import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import SubItemGroup from './SubItemGroup'; //子層
import './SideDrawer.css';
import { exit } from '../../../actions/LoginAction';
import { initBank } from '../../../actions/BankAction';
import { logOutBegin, change_time, logOutEnd,sideDrawer_close } from '../../../actions/AppAction';
import { alertBegin, alertShow, alertHide } from '../../../actions/AlertAction';
import { logoutUrl } from '../../../utils/apiList';



class SideDrawer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentIdx: 0
        }
    }
    static contextTypes = {
        functionList: PropTypes.array,
        sideDrawerOpen: PropTypes.bool,
        logoutHander: PropTypes.func
    }    
    render() {
        const { account, username, functionList, sideDrawerOpen } = this.props;
        let drawerClassName = 'side-drawer';
        if (sideDrawerOpen) {drawerClassName = 'side-drawer open';}
        return (
            <nav className={drawerClassName}>
                {/* 帳號 + 使用者名稱 */}
                <div className='side-drawer-placeholder-container'>
                    {account} {username}
                </div>
                <ul className='side-drawer-menuList'>
                    {
                        functionList?.length > 0 &&
                        functionList.filter(item => item.ParentId === '' && item.Type!=="title" ).map(filterItem => {
                            return (
                                <li className="menuList" key={filterItem.FunctionId}>
                                    {
                                        filterItem.Type === 'text' ? ( <div className="dropdown-title text">{filterItem.FunctionName}</div>)
                                        :(
                                            <div className={`dropdown-title ${filterItem.Type ==='url' ? 'no-subGroup': 'subGroup'}`} onClick={()=>{this.setCurrentIdx(filterItem.FunctionId)}}>
                                                {
                                                    (
                                                        ()=>{
                                                            if(filterItem.Type ==='url'){
                                                                if(filterItem.FunctionId !== 20) {
                                                                    return <Link to={filterItem.Url} onClick={(event) => this.linkItemClick(event)}>{filterItem.FunctionName}</Link>
                                                                }
                                                            }else if ( filterItem.Type ==='text'){
                                                                return filterItem.FunctionName
                                                            }else{
                                                                return filterItem.FunctionName
                                                            }
                                                        }
                                                    )()
                                                }
                                            </div>
                                        )
                                    }
                                    
                                    <ul className={this.checkItemIdx(filterItem.FunctionId)}>
                                        <SubItemGroup data={functionList} parentId={filterItem.FunctionId} backdropClickHandler={this.props.backdropClickHandler} />
                                    </ul>
                                </li>
                            )
                        })
                    }


                    <li><Link to="/login" onClick={(event) => this.logoutHandler(event)}>登出</Link></li>
                </ul>
            </nav>
        )
    }
    linkClick(event) {event.preventDefault();}

    checkItemIdx = (idx) => {
        return idx === this.state.currentIdx ? "dropdown-menu-app active" : "dropdown-menu-app";
    };

    setCurrentIdx = (idx) => {
        this.setState({currentIdx: idx});        
    }

    linkItemClick = (event) => {
        // event.preventDefault();
        this.props.backdropClickHandler();
    }

    fetchLogout = () => {
        const {token,username} = this.props;
        const list = {
            msg: {
                UserName: username
            }
        }
        const url = logoutUrl();
        fetch( url,{
            method: 'POST',
            headers: new Headers({
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'token': token,
            }),
            body: JSON.stringify(list),
        }).then(response => response.json())
        .catch( (error) => {
            console.error('logout Error',error)
        })
        .then( response => {
            console.log('logout Success',response.msg);
        })
    }

    logoutHandler(event) {
        event.preventDefault();
        const bg_color = '#00994e';
        this.fetchLogout();
        this.props.initBank();
        this.props.logOutBegin();
        this.props.alertBegin(bg_color);
        let time = this.props.time;
        const timer = setInterval(() => {
            this.props.change_time(time);
            this.props.alertShow((time).toString());
            if (time === 0) {
                clearInterval(timer);
                this.props.alertHide((time).toString());
                this.props.logOutEnd();
                setTimeout(() => {
                    // 清空session
                    this.props.exit();
                }, 400);
            }
            time--;
        }, 400);
    }

}

SideDrawer.propTypes = {
    sideDrawerOpen: PropTypes.bool,
    account: PropTypes.string,
    username: PropTypes.string,
    roleId: PropTypes.number,
    functionList: PropTypes.array,
    time: PropTypes.number,
    logoutHandler: PropTypes.func,
    change_time: PropTypes.func,
    logOutEnd: PropTypes.func,
    exit: PropTypes.func,
    alertBegin: PropTypes.func,
    alertShow: PropTypes.func,
    alertHide: PropTypes.func,
    backdropClickHandler: PropTypes.func,
}


const mapStateToProps = (state) => ({
    sideDrawerOpen: state.appReducer.sideDrawerOpen,
    account: state.loginReducer.account,
    username: state.loginReducer.username,
    roleId: state.loginReducer.roleId,
    token: state.loginReducer.token,
    functionList: state.loginReducer.functionList,
    time: state.appReducer.time,
    backdropClickHandler: PropTypes.func,
})
const mapDispatchToProps = (dispatch) => ({
    logOutBegin: () => dispatch(logOutBegin()),
    change_time: (time) => dispatch(change_time(time)),
    logOutEnd: () => dispatch(logOutEnd()),
    exit: () => dispatch(exit()),
    alertBegin: (bg_color) => dispatch(alertBegin(bg_color)),
    alertShow: (msg) => dispatch(alertShow(msg)),
    alertHide: () => dispatch(alertHide()),
    backdropClickHandler: () => dispatch(sideDrawer_close()),
    initBank: () => dispatch(initBank()),
})


export default connect(mapStateToProps, mapDispatchToProps)(SideDrawer);