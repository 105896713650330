import {
    LOGOUT_BEGIN,
    CHANGE_TIME,
    LOGOUT_END,
    SIDEDRAWER_OPEN,
    SIDEDRAWER_CLOSE,
} from '../constants/App-action-type';


export const logOutBegin = () => ({
    type: LOGOUT_BEGIN
})

export const change_time = (time) => ({
    type: CHANGE_TIME,
    payload: {
        time: time
    }
})
export const logOutEnd = () => ({
    type: LOGOUT_END
})

export const sideDrawer_open = () => ({
    type: SIDEDRAWER_OPEN
})

export const sideDrawer_close = () => ({
    type: SIDEDRAWER_CLOSE
})





