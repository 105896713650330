import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import './MsgBox.css';
import { alertHide } from '../../actions/AlertAction';


class MsgBox extends Component {
    render() {
        return (
            <CSSTransition
                in={this.props.isAlert}                
                classNames='msgboxCss'
                timeout={1000}
                unmountOnExit>
                <div className='msgbox' style={{ background: this.props.bg_color }}>
                    <h5 className='mb-0'>{this.props.msg}</h5>
                    <button type='button' className="closeButton" onClick={this.props.alertHide}>&times;</button>
                </div>
            </CSSTransition>
        )
    }
}

MsgBox.propTypes = {
    isAlert: PropTypes.bool,
    bg_color: PropTypes.string,
    msg: PropTypes.string,
    alertHide:PropTypes.func
}

const mapStateToProps = (state) => {
    return {
        isAlert: state.alertReducer.isAlert,
        bg_color: state.alertReducer.bg_color,
        msg: state.alertReducer.msg
    }
}

const mapDispatchToProps = (dispatch) => ({
    alertHide: () => dispatch(alertHide())
})

export default connect(mapStateToProps, mapDispatchToProps)(MsgBox);