import {
    // FETCH_AREALIST_BEGIN,
    FETCH_AREALIST_SUCCESS,
    FETCH_AREALIST_ERROR,
} from '../constants/AreaList-action-type';

// 初始化
const initialState = {
    // token: '',
    areaList: [],
    areaListError: ''
}

const AreaListReducer = (state = initialState , action) => {
    switch(action.type) {
        // case FETCH_AREALIST_BEGIN:
        //     return {
        //         ...state,
        //         // token: action.payload.token
        //     }
        case FETCH_AREALIST_SUCCESS:
            return {
                ...state,
                areaList: action.payload.areaList,
                areaListError: '',
            }
        case FETCH_AREALIST_ERROR:
            return {
                ...state,
                areaList: [],
                areaListError: action.payload.areaListError,
            }
        default: 
            return state
    }
}
export default AreaListReducer;