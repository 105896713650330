import { call, put, takeLatest } from 'redux-saga/effects';
import {
    UPDATA_BANKLIST,
    FETCH_BANKLIST_BEGIN,
    FETCH_BANKLIST_SUCCESS,
    FETCH_BANKLIST_ERROR,
} from '../constants/BankList-action-type';
import { bankListUrl } from '../utils/apiList';





export const updataBankList = (value) => ({
    type: UPDATA_BANKLIST,
    payload: {
        isUpdataBankList: value
    }
})


// 分行
export const fetchBankListBegin = (data) => ({
    type: FETCH_BANKLIST_BEGIN,
    payload: {
        token: data.token,
        city: data.city,
        postCodeNo: data.postCodeNo,
        account: data.account,
        bankList: [],
        bankListErrorMsg: '',
    }
})
export const fetchBankListSuccess = (data) => ({
    type: FETCH_BANKLIST_SUCCESS,
    payload: {
        bankList: data,
        bankListErrorMsg: '',
    }
})
export const fetchBankListError = (error) => ({
    type: FETCH_BANKLIST_ERROR,
    payload: {
        bankList: [],
        bankListErrorMsg: error,
    }
})





// 分行
function* fetchBankList(obj) {
    const { token, city, postCodeNo, account } = obj.payload;
    try {
        const url = bankListUrl({city,postCodeNo,account});
        const data = yield call(
            () => fetch(url, {
                method: "GET",
                headers: new Headers({
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'token': token,
                }),
            })
                .then(response => response.json())
        )
        if (data.code === '00') {
            yield put(fetchBankListSuccess(data.msg.List));            
        } else {
            yield put(fetchBankListError(data.msg));
        }
    } catch (error) {
        yield put(fetchBankListError(error))
    }
}


export function* BankListSaga() {
    // yield takeEvery('FETCH_ALLBANKLIST_BEGIN',fetchAllBankList)
    yield takeLatest('FETCH_BANKLIST_BEGIN', fetchBankList)
}