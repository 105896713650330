import { SUBMIT,CHANGE_ACCOUNT, CHANGE_PASSWORD, EXIT, FETCH_DATA_SUCCESS, FETCH_DATA_ERROR } from '../constants/Login-action-type';

// 初始化
const initialState = {
    account: '',
    password: '',
    redirectToReferrer: false,
    token: '',
    username: '', //權限
    roleId: '',
    company: '',
    functionList: [],
    SystemId: '',
    perPage: 10,
    error: '',
    data: {},
}

// 登入時的狀態類型，由 /constants/login-action-type 建立出來
const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUBMIT:
            return {
                ...state,
                redirectToReferrer: action.payload.redirectToReferrer,
                error: ''
            }
        case CHANGE_ACCOUNT:
            return {
                ...state,
                account: action.payload.account,
                error: ''
            }
        case CHANGE_PASSWORD:
            return {
                ...state,
                password: action.payload.password,
                error: ''
            }
        case EXIT:
            return {
                account: '',
                password: '',
                redirectToReferrer: false,
                token: '',
                username: '', //權限
                roleId: '',
                company: '',
                functionList: [],
                SystemId: '',
                error: '',
                data: {},
            }
        case FETCH_DATA_SUCCESS:
            return {
                ...state,
                password: '',
                token: action.payload.token, 
                username: action.payload.username,
                roleId: action.payload.roleId,
                company: action.payload.Company,
                functionList: action.payload.functionList,
                SystemId: action.payload.SystemId,
                data: action.payload.data,
                perPage: 10,
                error: ''
            }
        case FETCH_DATA_ERROR:
            return {
                ...state,
                error: action.payload.error,
            }
        default:
            return state
    }
}

export default loginReducer