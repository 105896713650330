import {
    FETCH_CITYGROUP_BEGIN,
    FETCH_CITYGROUP_SUCCESS,
    FETCH_CITYGROUP_ERROR,
    FETCH_CITY_BEGIN,
    FETCH_CITY_SUCCESS,
    FETCH_CITY_ERROR,
    FETCH_ALLCITY_BEGIN,
    FETCH_ALLCITY_SUCCESS,
    FETCH_ALLCITY_ERROR,
    FETCH_CITYOTHER_BEGIN,
    FETCH_CITYOTHER_SUCCESS,
    FETCH_CITYOTHER_ERROR,
}from '../constants/CityList-action-type';



const initialState = {
    // token: '',
    // 縣市群組(六都+其他)
    cityGroupList: [],
    cityGroupErrorMsg: '',
    // 僅包含現有分行縣市
    cityList: [],
    cityErrorMsg: '',
    // 全台
    allCityList: [],
    allCityListErrorMsg:'',
    // 縣市群組: 其他
    cityOtherList: [],
    cityOtherListErrorMsg: '',
}
const CityListReducer = (state = initialState, action) => {
    switch(action.type) {
        // 縣市群組
        case FETCH_CITYGROUP_BEGIN:
            return {
                ...state,
                // token: action.payload.token,
                cityGroupErrorMsg:'',
            }
        case FETCH_CITYGROUP_SUCCESS:
            return {
                ...state,
                cityGroupList: action.payload.cityGroupList,
                cityGroupErrorMsg:'',
            }
        case FETCH_CITYGROUP_ERROR:
            return {
                ...state,
                cityGroupList: [],
                cityGroupErrorMsg: action.payload.cityGroupErrorMsg,
            }
        // 僅包含現有分行縣市
        case FETCH_CITY_BEGIN:
            return {
                ...state,
                // token: action.payload.token,
                cityGroup: [],
                cityErrorMsg: '',
            }
        case FETCH_CITY_SUCCESS:
            return {
                ...state,
                cityList: action.payload.cityList, 
                cityErrorMsg: ''
            }
        case FETCH_CITY_ERROR:
            return {
                ...state,
                cityList:[],
                cityErrorMsg: action.payload.cityErrorMsg
            }
        // 全台
        case FETCH_ALLCITY_BEGIN:
            return {
                ...state,
                // token: action.payload.token,
                allCityList:[],
                allCityListErrorMsg:''
            }
        case FETCH_ALLCITY_SUCCESS:
            return {
                ...state,
                allCityList: action.payload.allCityList, 
                allCityListErrorMsg: ''
            }
        case FETCH_ALLCITY_ERROR:
            return {
                ...state,
                allCityList:[],
                allCityListErrorMsg: action.payload.allCityListErrorMsg
            }
        // 縣市群組 -- 其他
        case FETCH_CITYOTHER_BEGIN:
            return {
                ...state,
                // token: action.payload.token,
                cityOtherListErrorMsg: '',
            }
        case FETCH_CITYOTHER_SUCCESS:
            return {
                ...state,
                cityOtherList: action.payload.cityOtherList,
                cityOtherListErrorMsg: ''
            }
        case FETCH_CITYOTHER_ERROR:
            return {
                ...state,
                cityOtherList: [],
                cityOtherListErrorMsg: action.payload.cityOtherListErrorMsg
            }
        default:
            return state
    }
}

export default CityListReducer