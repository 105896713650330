import {call,put,takeLatest} from 'redux-saga/effects';
import { 
    FETCH_USAGELIST_BEGIN,
    FETCH_USAGELIST_SUCCESS
    ,FETCH_USAGELIST_ERROR
} from '../constants/UsageList-action-type';
import { usageListUrl } from '../utils/apiList';


export const fetchUsageListBegin = (token) => ({
    type: FETCH_USAGELIST_BEGIN,
    payload: {
        token: token,
        usageList: [],
        usageListError:'',
    }
})

export const fetchUsageListSuccess = (data) => ({
    type: FETCH_USAGELIST_SUCCESS,
    payload: {
        usageList: data,
        usageListError: ''
    }
})

export const fetchUsageListError = (error) => ({
    type: FETCH_USAGELIST_ERROR,
    payload: {
        usageListError: error
    }
})

function* fetchUsageList(obj) {
    const token = obj.payload.token;

    try {
        const url = usageListUrl();
        const data = yield call(
            () => fetch(url,{
                method: 'GET',
                headers: new Headers({
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'token': token,
                })
            })
            .then( response => response.json())
        )
        if (data.code === "00" && data.msg) {
            yield put(fetchUsageListSuccess(data.msg.List))
        }else {
            yield put( fetchUsageListError(data.msg))
        }
    }catch(error) {
        yield put (fetchUsageListError(error))
    }
}

export function* UsageListSaga() {
    yield takeLatest('FETCH_USAGELIST_BEGIN',fetchUsageList)
}