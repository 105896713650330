
const ajax = async(url, protocol, token, postData) => {
    const controller = new AbortController();
    setTimeout(() => {
        controller.abort();
    }, 5000) // 5hr = 18000, 3hr= 10800
    try {
        let response = await fetch(url, {
            method: protocol,
            headers: new Headers({
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'token': token,
            }),
            body: JSON.stringify(postData),
            signal:controller.singal
        })

        let responseJson = await response.json();

        return responseJson
    } catch (error) {
        return {};
    }
}
ajax.defaultProps = {
    token: '',
    postData: '',
}
export default ajax;