import {
    UPDATA_BANKLIST,
    FETCH_BANKLIST_BEGIN,
    FETCH_BANKLIST_SUCCESS,
    FETCH_BANKLIST_ERROR,
}from '../constants/BankList-action-type';


const initialState = {
    // token: '',
    city: '',
    postCodeNo:'',
    // 全分行清單
    allBankList:[],
    allBankListErrorMsg:'',
    // 分行清單
    bankList:[],
    bankListErrorMsg:'',
    isUpdataBankList: false,
}
const BankListReducer = (state = initialState, action) => {
    switch(action.type) {
        // 更新分行清單
        case UPDATA_BANKLIST:
            return {
                ...state,
                isUpdataBankList: action.payload.isUpdataBankList,
            }
        // 分行清單
        case FETCH_BANKLIST_BEGIN:
            return {
                ...state,
                // token: action.payload.token,
                city: action.payload.city,
                postCodeNo: action.payload.postCodeNo,
                bankList:[],
                bankListErrorMsg: '',
            }
        case FETCH_BANKLIST_SUCCESS:
            return {
                ...state,
                bankList: action.payload.bankList,
                bankListErrorMsg: '',
            }
        case FETCH_BANKLIST_ERROR:
            return {
                ...state,
                bankList: [],
                bankListErrorMsg: action.payload.bankListErrorMsg,
            }
        default:
            return state
    }
}

export default BankListReducer