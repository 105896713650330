import { combineReducers } from 'redux';
import loginReducer from './LoginReducer';
import appReducer from './AppReducer';
import alertReducer from './AlertReducer';
import DashboardReducer from './DashboardReducer'; //首頁(跳轉排行模式selectedCity)
import BankReducer from './BankReducer';// 各分行數據
import BankRankReducer from './BankRankReducer';//分行排行
import CityRankReducer from './CityRankReducer'; //縣市排行
import MeterReducer from './MeterReducer';//電表數據
import AccountReducer from './AccountReducer';//帳號管理
// 下拉清單
import CityListReducer from './CityListReducer';
import DistListReducer from './DistListReducer';
import BankListReducer from './BankListReducer';
import RatePlanListReducer from './RatePlanListReducer';
import PATypeListReducer from './PATypeListReducer';
import UsageListReducer from './UsageListReducer';
import WiringListReducer from './WiringListReducer';
import DFListReducer from './DFListReducer';
import MeterListReducer from './MeterListReducer';
import MeterTypeListReducer from './MeterTypeListReducer';
import RankListReducer from './RankListReducer';
import AreaListReducer from './AreaListReducer';
// 目標值
import KPIReducer from './KPIReducer';

const rootReducer = combineReducers({
    loginReducer: loginReducer,
    appReducer: appReducer,
    alertReducer: alertReducer,

    DashboardReducer:DashboardReducer,

    BankRankReducer: BankRankReducer,
    CityRankReducer: CityRankReducer,
    BankReducer: BankReducer, // 各分行數據
    AccountReducer: AccountReducer,//帳號管理

    CityListReducer: CityListReducer, //city
    DistListReducer: DistListReducer,
    BankListReducer: BankListReducer,
    MeterReducer: MeterReducer,//利用deviceID跳轉至Bank-Meter頁面

    RatePlanListReducer: RatePlanListReducer,
    PATypeListReducer: PATypeListReducer,
    UsageListReducer: UsageListReducer,
    WiringListReducer: WiringListReducer,
    DFListReducer: DFListReducer,
    MeterListReducer: MeterListReducer,//電表總覽
    MeterTypeListReducer: MeterTypeListReducer,
    RankListReducer: RankListReducer,//帳號管理 權限清單
    AreaListReducer: AreaListReducer,//帳號管理 區域名稱清單

    KPIReducer: KPIReducer,

});


export default rootReducer;