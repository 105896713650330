// 取得分行排行 (資料整理)
export const getBankRankList = (bankRank, type, KPIList) => {
	const valueList = []; // 數值
	const unUseList = []; // max(200) - 已使用數值
	const colorList = []; // 長條圖顯示顏色
	const labelList = []; // 分行編號+分行名稱
	const bankCodeList = []; // 分行編號(切換分頁用)
	const link = window.location.href;

	bankRank.map((item) => {
		valueList.push(item.Value);
		unUseList.push(200 - item.Value);
		labelList.push(`${item.BankCode} ${item.BankName}`);
		bankCodeList.push(item.BankCode);
		if (type === "W") {	//功率
			if (item.Value > KPIList.WKPI) {
				colorList.push("#f37200");
			} else {
				colorList.push("#F8B92D");
			}
		} else if (type === 'Charge') { //電費
			if (item.Value > KPIList.UnitPriceKPI) {
				colorList.push("#f37200");
			} else {
				colorList.push("#F8B92D");
			}
		} else if ( type === 'CEC'){ // 用電量
			if (item.Value > KPIList.UnitPriceKPI) {
				colorList.push("#f37200");
			} else {
				colorList.push("#F8B92D");
			}
		} else if ( type === 'Price'){	//平均單價
			if (item.Value > KPIList.UnitPriceKPI) {
				colorList.push("#f37200");
			} else {
				colorList.push("#F8B92D");
			}
		} else if (type === "EUI") {
			if (item.Value > KPIList.EUIKPI) {
				colorList.push("#f37200");
			} else {
				colorList.push("#F8B92D");
			}
		} else if (type === "EPUI") {
			if (item.Value > KPIList.EPUIKPI) {
				colorList.push("#f37200");
			} else {
				colorList.push("#F8B92D");
			}
		} else if (type === "Air") { //主要空調
			if (item.Value > KPIList.AirKPI) {
				colorList.push("#f37200");
			} else {
				colorList.push("#F8B92D");
			}
		} else if ( type === 'Over'){ //非約定電費
			colorList.push("#F39800");
		} 
		return '';
	});

	return {
		valueList,
		unUseList,
		colorList,
		labelList,
		bankCodeList,
		link,
	};
};
// 取得圖表名稱
export const getChartTitle = (type) => {
	let name = "";
	switch (type) {
		case "W":
			name = "需量預測排行";
			break;
		case "Air":
			name = "主要空調佔比排行";
			break;
		case "Charge":
			name = "上月單價排行";
			break;
		case "CEC":
			name = "上月單價排行";
			break;
		case "Price":
			name = "上月單價排行";
			break;
		case "EUI":
			name = "EUI排行(KWH/㎡)";
			break;
		case "EPUI":
			name = "EPUI佔比排行(KWH/人)";
			break;
		case "Over":
			name = "最大需量%排行";
			break;
		default:
			name = "需量預測排行";
	}
	return name;
};
// 取得圖表目標值
export const getChartKPI = (type, KPIList) => {
	let KPI = 0;
	switch (type) {
		case "W":
			KPI = KPIList.WKPI;
			break;
		case "Charge":
			KPI = KPIList.UnitPriceKPI;
			break;
		case "CEC":
			KPI = KPIList.UnitPriceKPI;
			break;
		case "Price":
			KPI = KPIList.UnitPriceKPI;
			break;
		case "EUI":
			KPI = KPIList.EUIKPI;
			break;
		case "EPUI":
			KPI = KPIList.EPUIKPI;
			break;
		case "Air":
			KPI = KPIList.AirKPI;
			break;
		case "Over":
			KPI = KPIList.WKPI;
			break;
		default:
			KPI = 0;
	}
	return KPI
};

// 取得圖表目標值單位
export const getChartKPIUnit = (type) => {
	let unit = '';
	switch (type) {
		case "W":
			unit = '%';
			break;
		case "Charge":
			unit = '';
			break;
		case "CEC":
			unit = '';
			break;
		case "Price":
			unit = '';
			break;
		case "EUI":
			unit = '';
			break;
		case "EPUI":
			unit = '';
			break;
		case "Air":
			unit = '%';
			break;
		case "Over":
			unit = '';
			break;
		default:
			unit = '';
	}
	return unit
};


//取得排名圖表最大值
export const getMaxxAxes = (type) => {
	let value = 0;
	switch (type) {
		case 'W':
			value = 160;
			break;
		case "Charge":
			value = 10;
			break;
		case "CEC":
			value = 10;
			break;
		case "Price":
			value = 10;
			break;
		case "EUI":
			value = 160;
			break;
		case "EPUI":
			value = 160;
			break;
		case "Air":
			value = 100;
			break;
		case "Over":
			value = 160;
			break;
		default:
			value = 160;
	}
	return value
}