export const checkFunctionList = (list, currentHash,roleId) => {
	let check = false;
    const hashList = list.filter(fitlerItem => fitlerItem.Url !== '').map(element => element.Url);
	
    if(hashList && hashList.length > 0) {

        if(hashList.indexOf(currentHash) > -1) {
            check = true
        }else if  (currentHash === '/') {
            check = true
        }else if  (currentHash === '/login') {  // 登入
            check = true
        }else if  (currentHash === '/meter') { // 分行數據-電表資訊
            check = true
        }else if  (currentHash === '/areaManage' && (roleId === 0 || roleId === 1 )) { // 管理與設定-區域管理
            check = true
        }else if  (currentHash === '/PrivacyPolicy') {  //  隱私權政策及網站安全政策
            check = true
        }
    }
    
	return check;
};
checkFunctionList.defaultProps = {
    roleId: null
}
