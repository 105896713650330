import React from 'react';
import { Link } from 'react-router-dom';


const SubItemGroup = ({data,parentId,backdropClickHandler}) => {
    const linkItemClick = (event) => {
        // event.preventDefault();
        backdropClickHandler();
    }
    return (
        <>
        {
            data.filter(item => item.ParentId === parentId).map((filterItem,idx)=> {
                return <div key={idx}>{
                    (
                        ()=>{
                            if(filterItem.Type ==='url'){
                                return <li key={filterItem.FunctionId}><Link to={filterItem.Url} onClick={(event) => linkItemClick(event)}>{filterItem.FunctionName}</Link></li>
                            }else if ( filterItem.Type ==='text'){
                                return <li key={filterItem.FunctionId} className="text"><Link to='/'>{filterItem.FunctionName}</Link></li>
                            }else{
                                return <li key={filterItem.FunctionId} className="text"><Link to='/'>{filterItem.FunctionName}</Link></li>
                            }
                        }
                    )()
                }</div>
            })
        }
        </>
    )
}

export default SubItemGroup;