import { call, put, takeEvery } from 'redux-saga/effects';
import {
    FETCH_CITYGROUP_BEGIN,
    FETCH_CITYGROUP_SUCCESS,
    FETCH_CITYGROUP_ERROR,
    FETCH_CITY_BEGIN,
    FETCH_CITY_SUCCESS,
    FETCH_CITY_ERROR,
    FETCH_ALLCITY_BEGIN,
    FETCH_ALLCITY_SUCCESS,
    FETCH_ALLCITY_ERROR,
    FETCH_CITYOTHER_BEGIN,
    FETCH_CITYOTHER_SUCCESS,
    FETCH_CITYOTHER_ERROR,
} from '../constants/CityList-action-type';
import { cityGroupListUrl, allCityListUrl, cityListUrl, otherCityListUrl } from '../utils/apiList';



// get api
export const fetchCityGroupListBegin = (token) => ({
    type: FETCH_CITYGROUP_BEGIN,
    payload: {
        token: token,
        cityGroupList: [],
        cityGroupErrorMsg: '',
    }
})
export const fetchCityGroupListSuccess = (data) => ({
    type: FETCH_CITYGROUP_SUCCESS,
    payload: {
        cityGroupList: data,
        cityGroupErrorMsg: '',
    }
})
export const fetchCityGroupListError = (error) => ({
    type: FETCH_CITYGROUP_ERROR,
    payload: {
        cityGroupList: [],
        cityGroupErrorMsg: error,
    }
})





// 僅包含現有分行縣市
export const fetchCityListBegin = (data) => ({
    type: FETCH_CITY_BEGIN,
    payload: {
        token: data.token,
        account: data.account,
        cityList: [],
        cityErrorMsg: '',
    }
})
export const fetchCityListSuccess = (data) => {
    return {
        type: FETCH_CITY_SUCCESS,
        payload: {
            cityList: data,
            cityErrorMsg: ''
        }
    }
}
export const fetchCityListError = (error) => {
    return {
        type: FETCH_CITY_ERROR,
        payload: {
            cityList: [],
            cityErrorMsg: error
        }
    }
}





// 全台
export const fetchAllCityListBegin = (token) => ({
    type: FETCH_ALLCITY_BEGIN,
    payload: {
        token: token,
        allCityList: [],
        allCityListErrorMsg: '',
    }
})
export const fetchAllCityListSuccess = (data) => ({
    type: FETCH_ALLCITY_SUCCESS,
    payload: {
        allCityList: data,
        allCityListErrorMsg: '',
    }
})
export const fetchAllCityListError = (error) => ({
    type: FETCH_ALLCITY_ERROR,
    payload: {
        allCityList: [],
        allCityListErrorMsg: error,
    }
})





export const fetchCityOtherListBegin = (token) => ({
    type: FETCH_CITYOTHER_BEGIN,
    payload: {
        token: token,
        cityOtherList: [],
        cityOtherListErrorMsg: '',
    }
})
export const fetchCityOtherListSuccess = (data) => ({
    type: FETCH_CITYOTHER_SUCCESS,
    payload: {
        cityOtherList: data,
        cityOtherListErrorMsg: '',
    }
})
export const fetchCityOtherListError = (error) => ({
    type: FETCH_CITYOTHER_ERROR,
    payload: {
        cityOtherList: [],
        cityOtherListErrorMsg: error,
    }
})





function* fetchCityGroupList(obj) {
    const token = obj.payload.token;
    try {
        const url = cityGroupListUrl();
        const data = yield call(
            () => fetch(url, {
                method: 'GET',
                headers: new Headers({
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'token': token,
                }),
            }).then(response => response.json())
        )

        if (data.code === '00') {
            yield put(fetchCityGroupListSuccess(data.msg.List));
        } else {
            yield put(fetchCityGroupListError(data.msg));
        }
    } catch (error) {
        yield put(fetchCityGroupListError(error))
    }
}

// 僅包含現有分行縣市
function* fetchCityList(obj) {
    const { token, account } = obj.payload;
    try {
        const url = cityListUrl({cityGroup:'',account});
        const data = yield call(
            () => fetch(url, {
                method: "GET",
                headers: new Headers({
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'token': token,
                    // 'account':account,
                    // 'cityGroup': '',
                }),
            })
                .then(response => response.json())
        )
        if (data.code === '00') {
            yield put(fetchCityListSuccess(data.msg.List));
        } else {
            yield put(fetchCityListError(data.msg));
        }
    } catch (error) {
        yield put(fetchCityListError(error))
    }
}

// 全台
function* fetchAllCityList(obj) {
    const token = obj.payload.token;
    try {
        const url = allCityListUrl();
        const data = yield call(
            () => fetch(url, {
                method: "GET",
                headers: new Headers({
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'token': token,
                }),
            })
                .then(response => response.json())
        )
        if (data.code === '00') {
            yield put(fetchAllCityListSuccess(data.msg.List));
        } else {
            yield put(fetchAllCityListError(data.msg));
        }
    } catch (error) {
        yield put(fetchAllCityListError(error))
    }
}
// 排除六都外所有，有分行縣市
function* fetchCityOtherList(obj) {
    const token = obj.payload.token;
    try {
        const url = otherCityListUrl();
        const data = yield call(
            () => fetch(url, {
                method: "GET",
                headers: new Headers({
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'token': token,
                }),
            })
                .then(response => response.json())
        )
        if (data.code === '00') {
            yield put(fetchCityOtherListSuccess(data.msg.List));
        } else {
            yield put(fetchCityOtherListError(data.msg));
        }
    } catch (error) {
        yield put(fetchCityOtherListError(error))
    }
}





export function* CityListSaga() {
    yield takeEvery('FETCH_CITYGROUP_BEGIN', fetchCityGroupList)
    yield takeEvery('FETCH_CITY_BEGIN', fetchCityList)
    yield takeEvery('FETCH_ALLCITY_BEGIN', fetchAllCityList)
    yield takeEvery('FETCH_CITYOTHER_BEGIN', fetchCityOtherList)
}