import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sideDrawer_close } from '../../../actions/AppAction';

import './Backdrop.css';

class Backdrop extends Component {
    static contextTypes = {
        backdropClickHandler: PropTypes.func
    }
    render() {
        return (
            <div className='backdrop' onClick={this.props.backdropClickHandler} />
        )
    }
}

Backdrop.propTypes = {
    backdropClickHandler: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => ({
    backdropClickHandler: () => dispatch(sideDrawer_close())
})

export default connect(null, mapDispatchToProps)(Backdrop);