import { call, put, takeLatest } from 'redux-saga/effects';
import {
    FETCH_DISTLIST_BEGIN,
    FETCH_DISTLIST_SUCCESS,
    FETCH_DISTLIST_ERROR,
} from '../constants/DistList-action-type';
import { distListUrl } from '../utils/apiList';


// get api
export const fetchDistListBegin = (data) => ({
    type: FETCH_DISTLIST_BEGIN,
    payload: {
        token: data.token,
        city: data.city,
        account: data.account,
        distList: [],
        distListError: '',
    }
})
export const fetchDistListSuccess = (data) => ({
    type: FETCH_DISTLIST_SUCCESS,
    payload: {
        distList: data,
        distListError: '',
    }
})
export const fetchDistListError = (error) => ({
    type: FETCH_DISTLIST_ERROR,
    payload: {
        distList: [],
        distListError: error,
    }
})

function* fetchDistList(obj) {
    const { token, city, account } = obj.payload;
    try {
        // const url = `${apiUrl}getDistList?city=${city}&account=${account}`;
        const url = distListUrl({city,account,type:''});
        const data = yield call(
            () => fetch(url, {
                method: "GET",
                headers: new Headers({
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'token': token,
                }),
            })
                .then(response => response.json())
        )
        if (data.code === '00') {
            yield put(fetchDistListSuccess(data.msg.List));
        } else {
            yield put(fetchDistListError(data.msg));
        }
    } catch (error) {
        yield put(fetchDistListError(error))
    }
}

export function* DistListSaga() {
    yield takeLatest('FETCH_DISTLIST_BEGIN', fetchDistList)
}