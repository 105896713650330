import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';
// import ToggleButton from '../SideDrawer/ToggleButton';
import './Toolbar.scss';
import { exit } from '../../../actions/LoginAction';
import { logOutBegin, change_time, logOutEnd } from '../../../actions/AppAction';
import { alertBegin, alertShow, alertHide } from '../../../actions/AlertAction';
import cathaybkLogo from '../../../image/cathaybk logo.svg'

class Toolbar extends Component {
    render() {        
        return (
            <header className='toolbar'>
                <nav className='toolbar__navigetion'>
                    {/* <div className='toolbar__logo'> */}
                    <span className="green-bg">
                        <div className='toolbar__toogle-button'>
                            <DrawerToggleButton />
                            {/* <ToggleButton /> */}
                        </div>
                        <span className="ECO-logo">ECO-Cloud<br />雲端能管系統</span>
                    </span>

                    <span className="yellow-bg">                      
                        <Link to='/'>
                            <img className="cathybk-logo" src={cathaybkLogo} alt="cathaybk-logo" />
                        </Link>
                    </span>
                </nav>
            </header>
        )
    }
    linkClick(event) {
        event.preventDefault();
    }

    logoutHandler(event) {
        event.preventDefault();
        const bg_color = '#f7a7a3';
        this.props.logOutBegin();
        this.props.alertBegin(bg_color);
        let time = this.props.time;
        const timer = setInterval(() => {
            this.props.change_time(time);
            this.props.alertShow((time).toString());
            if (time === 0) {
                clearInterval(timer);
                this.props.alertHide((time).toString());
                this.props.logOutEnd();
                setTimeout(() => {
                    this.props.exit();
                }, 1000);
            }
            time--;
        }, 1000);
    }

}

Toolbar.propTypes = {
    menu: PropTypes.array,
    time: PropTypes.number,
    logoutHandler: PropTypes.func,
    change_time: PropTypes.func,
    logOutEnd: PropTypes.func,
    exit: PropTypes.func,
    alertBegin: PropTypes.func,
    alertShow: PropTypes.func,
    alertHide: PropTypes.func,    
}

const mapStateToProps = (state, ownProps) => ({
    menu: state.loginReducer.menu,
    time: state.appReducer.time,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    logOutBegin: () => dispatch(logOutBegin()),
    change_time: (time) => dispatch(change_time(time)),
    logOutEnd: () => dispatch(logOutEnd()),
    exit: () => dispatch(exit()),
    alertBegin: (bg_color) => dispatch(alertBegin(bg_color)),
    alertShow: (msg) => dispatch(alertShow(msg)),
    alertHide: () => dispatch(alertHide()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);