import React , { Component, Fragment } from 'react';
import {connect} from 'react-redux';


class ApplyContent extends Component {
    constructor(props){
        super(props)
        this.state={}
    }

    componentDidMount() {}

    componentWillUnmount() {
        this.props.handleCancel();
    }

    render(){
        const { loading } = this.props;
        const { Step, Account, UserName, VerifyCode, Step3Account, Password, PasswordCheck, isOpen, message, } = this.props.content;
        return (
            <Fragment>
                <div className={`alert alert-warning ${isOpen ? 'd-block' : 'd-none'}`}>{message}</div>

                {/* Step1 輸入帳號,使用者名稱 */}
                <div className={Step === '1' ? 'd-block' : 'd-none'}>
                    <div className='login__field'>
                        <input type="text" name="Account" value={Account} onChange={(event) => this.changeInput(event)} />
                        <label>帳號</label>
                    </div>
                    <div className='login__field'>
                        <input type='text' name="UserName" value={UserName} onChange={(event) => this.changeInput(event)} />
                        <label>使用者名稱</label>
                    </div>

                    <div className="text-center">
                        {
                            (Account !== '' && UserName !=='' && loading === false) 
                            ? (<button className="btn pl-4 pr-4 mr-2 btn-success" onClick={()=>{this.redirectToStep2({Account,UserName})}}>送出</button>)
                            : <button className="btn pl-4 pr-4 mr-2 btn-secondary disabled">送出</button>
                        }
                        <button className="btn btn-success pl-4 pr-4 " onClick={this.props.handleCancel}>取消</button>
                    </div>
                </div>

                {/* Step2 輸入驗證碼 */}
                <div className={Step === '2' ? 'd-block' : 'd-none'}>
                    <div className='login__field mb-4'>
                        已寄出驗證信至 {this.props.email ? this.props.email : ''} 請於10分鐘內輸入驗證碼
                    </div>
                    <div className='login__field'>
                        <input type="text" name="VerifyCode" value={VerifyCode} onChange={(event) => this.changeInput(event)} />
                        <label>驗證碼</label>
                    </div>

                    <div className="text-center">
                        {
                            (VerifyCode !== '' && loading === false)
                            ? <button className="btn pl-4 pr-4 mr-2 btn-success" onClick={()=>{this.redirectToStep3({Account,VerifyCode})}}>送出</button>
                            : <button className="btn pl-4 pr-4 mr-2 btn-secondary disabled">送出</button>
                        }
                        <button className="btn btn-success pl-4 pr-4 " onClick={this.props.handleCancel}>取消</button>
                    </div>
                </div>

                {/* Step3 設定密碼 */}
                <div className={Step === '3' ? 'd-block' : 'd-none'}>
                    <div className='login__field'>
                        <input type="text" name="Step3Account" value={Step3Account} onChange={(event) => this.changeInput(event)} />
                        <label>帳號</label>
                    </div>
                    <div className='login__field'>
                        <input type="password" name="Password" value={Password} onChange={(event) => this.changeInput(event)} />
                        {/* <label>密碼</label> */}
                        <label>新設密碼</label>
                    </div>
                    <div className='login__field'>
                        <input type="password" name="PasswordCheck" value={PasswordCheck} onChange={(event) => this.changeInput(event)} />
                        <label>確認密碼</label>
                    </div>

                    <div className="text-center">
                        {
                            (Step3Account !== '' && Password !== '' && PasswordCheck !== '' && loading === false)
                            ? <button className="btn pl-4 pr-4 mr-2 btn-success" onClick={()=>{this.changePassword({Account,Password,PasswordCheck})}}>送出</button>
                            : <button className="btn pl-4 pr-4 mr-2 btn-secondary disabled">送出</button>
                        }
                        <button className="btn btn-success pl-4 pr-4 " onClick={this.props.handleCancel}>取消</button>
                    </div>
                </div>

                {/* Step4 完成訊息 */}
                <div className={Step === '4' ? 'd-block' : 'd-none'}>
                    <div className="text-center">
                        <button className={`btn pl-4 pr-4 mr-2 ${loading ? 'btn-secondary disabled' : 'btn-success'}`} onClick={this.props.handleCancel}>返回</button>
                    </div>
                </div>
                
            </Fragment>
        )
    }


    // 變更input內容
    changeInput = (event) => {
        const obj = {
            id: this.props.id,
            name: event.target.name,
            value: event.target.value,
        }
        this.props.changeInputValue(obj)
    }

    // 跳轉到Step2 輸入驗證碼
    redirectToStep2 = (obj) => {
        const { id } = this.props;
        const { Account,UserName } = obj;
        const list = {id,Account,UserName}
        this.props.getApplyEmail(list);
    }

    // 驗證密碼,跳轉到Step3 設定密碼
    redirectToStep3 = (obj) => {
        const { id } = this.props;
        const { Account,VerifyCode } = obj;
        const list = {id,Account,VerifyCode}
        this.props.getCheckVerifyCode(list)
    }

    // 變更密碼
    changePassword = (obj) => {
        const { id } = this.props;
        const { Account,Password,PasswordCheck } = obj;
        const list = {id,Account,Password,PasswordCheck}
        this.props.hadleChangePassword(list);
    }

    

}

ApplyContent.defaultProps = {
    id: '',
    email: '',
    loading: false,
    getApplyEmail: () => {},
    getCheckVerifyCode: () => {},
    changeInputValue: () => {},
    hadleChangePassword: () => {},
    handleCancel: () => {},
}
const mapStateToProps = (state, ownProps) => {
    return {
        token: state.loginReducer.token,
        // username: state.loginReducer.username,
    }
}

export default connect(mapStateToProps)(ApplyContent);