import {call,put,takeLatest} from 'redux-saga/effects';
import {
    FETCH_WIRINGLIST_BEGIN,
    FETCH_WIRINGLIST_SUCCESS,
    FETCH_WIRINGLIST_ERROR
} from '../constants/WiringList-action-type';
import {wiringListUrl} from '../utils/apiList';

export const fetchWiringListBegin = (token) =>({
    type:FETCH_WIRINGLIST_BEGIN,
    payload: {
        token: token,
        wiringList: [],
        wiringListError: '',
    }
})

export const fetchWiringListSuccess = (data) =>({
    type:FETCH_WIRINGLIST_SUCCESS,
    payload: {
        wiringList: data,
        wiringListError: '',
    }
})

export const fetchWiringListError = (error) =>({
    type:FETCH_WIRINGLIST_ERROR,
    payload: {
        wiringList: [],
        wiringListError: error,
    }
})

function* fetchWiringList(obj) {
    const token = obj.payload.token;

    try {
        const url=wiringListUrl();
        const data = yield call(
            () => fetch(url,{
                method: 'GET',
                headers: new Headers({
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'token': token,
                })
            })
            .then( response => response.json())
        )

        if( data.code === '00' && data.msg) {
            yield put(fetchWiringListSuccess(data.msg.List))
        }else {
            yield put(fetchWiringListError(data.msg))
        }
    }catch(error){
        yield put(fetchWiringListError(error))
    }
}
export function* WiringListSaga() {
    yield takeLatest('FETCH_WIRINGLIST_BEGIN',fetchWiringList)
}