import {
    FETCH_RATEPLAN_BEGIN,
    FETCH_RATEPLAN_SUCCESS,
    FETCH_RATEPLAN_ERROR
} from '../constants/RatePlanList-action-type';
// 初始化
const initialState = {
    // token:'',
    ratePlanList: [],
    ratePlanListError:''
}
const RatePlanListReducer = (state = initialState , action) => {
    switch(action.type) {
        case FETCH_RATEPLAN_BEGIN:
            return {
                ...state,
                // token:action.payload.token,
            }
        case FETCH_RATEPLAN_SUCCESS:
            return {
                ...state,
                ratePlanList: action.payload.ratePlanList,
                ratePlanListError:''
            }
        case FETCH_RATEPLAN_ERROR:
            return {
                ...state,
                ratePlanList:[],
                ratePlanListError: action.payload.ratePlanListError
            }
        default:
            return state
    }

}
export default RatePlanListReducer;