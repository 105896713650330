import {call, put, takeLatest } from 'redux-saga/effects';
import {
    FETCH_DFLIST_BEGIN,
    FETCH_DFLIST_SUCCESS,
    FETCH_DFLIST_ERROR,
} from '../constants/DFList-action-type';
import { DFListUrl } from '../utils/apiList';

export const fetchDFListBegin = (token) => ({
    type: FETCH_DFLIST_BEGIN,
    payload: {
        token: token,
        DFList:[],
        DFListError: '',
    }
})

export const fetchDFListSuccess = (data) => ({
    type: FETCH_DFLIST_SUCCESS,
    payload: {
        DFList: data,
        DFListError: '',
    }
})

export const fetchDFListError = (error) => ({
    type: FETCH_DFLIST_ERROR,
    payload: {
        DFList:[],
        DFListError: error,
    }
})

function* fetchDFList(obj) {
    const token = obj.payload.token;

    try{
        const url = DFListUrl();
        const data = yield call(
            () => fetch(url,{
                method: 'GET',
                headers: new Headers({
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'token': token,
                })
            })
            .then( response => response.json())
        )

        if( data.code === '00' && data.msg) {
            yield put(fetchDFListSuccess(data.msg.List))
        }else {
            yield put(fetchDFListError(data.msg))
        }
    }catch(error){
        yield put(fetchDFListError(error))
    }
}

export function* DFListSaga() {
    yield takeLatest('FETCH_DFLIST_BEGIN',fetchDFList)
}