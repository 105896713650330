import React , {Component} from 'react';
import classNames from 'classnames';
import './cusTab.scss';

class CusTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentIdx: 0
        };
    }

    checkTitleIdx = (idx) => {
        return idx === this.state.currentIdx ? 'Tab_title active' : 'Tab_title';
    }

    checkItemIdx = (idx) => {
        return idx === this.state.currentIdx ? 'Tab_item show' : 'Tab_item';
    }

    clickHandler = (idx) => {
        this.setState({currentIdx: idx})
        // 點擊頁籤執行
        if(this.props.clickEvent) {
            this.props.clickEvent(idx)
        }
    }

    // style
    classTabAlign = classNames({
        'text-left': this.props.tabAlign === 'left',
        'text-right': this.props.tabAlign === 'right',
        'text-center': this.props.tabAlign === 'center',
    })
    classScrollTable = classNames({
        'scrollTable': this.props.scrollTable === true,
    })

    render() {
        // let _this = this;
        return (
            <>
                <div className={`Tab_title_wrap ${this.classTabAlign}`}>
                    {
                        // title
                        React.Children.map(this.props.children , (element,idx) => {
                            return (
                                <div 
                                    onClick={ () => {this.clickHandler(idx)}} 
                                    className={ this.checkTitleIdx(idx)}>
                                        {element.props.name}
                                </div>
                            )
                        })
                    }
                </div>
                {/* content */}
                <div className={`Tab_item_wrap ${this.classScrollTable}`}>
                    {
                        React.Children.map(this.props.children , ( element,idx) => {
                            return (
                                <div className={this.checkItemIdx(idx)} key={idx}>
                                    {element}
                                </div>
                            )
                        })
                    }
                </div>
            </>
        )
    }
}
CusTab.propsDefault={
    tabAlign:'left',
    scrollTable: false,
    clickEvent: () => {},
}
export default CusTab;