import { location } from './index';

const apiUrl = location();

// 登入
export const authUrl = () => {
    const url = `${apiUrl}authorization`;
    return url
}
// 登入 - 帳號申請
export const applyAccountUrl = () => {
    const url = `${apiUrl}applyAccount`;
    return url
}
// 登入 - 忘記密碼
export const forgetPasswordUrl = () => {
    const url = `${apiUrl}forgetPassword`;
    return url
}
// 登入 - 檢查驗證碼
export const checkVerifyCodeUrl = () => {
    const url = `${apiUrl}checkVerifyCode`;
    return url
}
// 登入 - 變更密碼
export const setupPasswordUrl = () => {
    const url = `${apiUrl}setupPassword`;
    return url
}
// 登出
export const logoutUrl = () => {
    const url = `${apiUrl}logout`;
    return url
}



// 總覽模式
// 總覽模式 - 分行排名
export const BankRankUrl = ({type}) => {
    const url = `${apiUrl}getBankRank?type=${type}`;
    return url
}
// 排行模式 - 縣市排名
export const cityRankUrl = ({type}) => {
    const url = `${apiUrl}getCityRank?type=${type}`;
    return url
}
// 排行模式 - 即時資訊
export const electricityInfoUrl = ({cityGroup,city,postCodeNo,bankCode,usageCode,filter,type}) => {
    const url = `${apiUrl}getElectricityInfo?cityGroup=${cityGroup}&city=${city}&postCodeNo=${postCodeNo}&bankCode=${bankCode}&usageCode=${usageCode}&filter=${filter}&type=${type}`;
    return url
}
// 排行模式 - 預測資訊
export const analysisInfoUrl = ({cityGroup,city,postCodeNo,bankCode,usageCode,lastCode,filter,type}) => {
    const url = `${apiUrl}getAnalysisInfo?cityGroup=${cityGroup}&city=${city}&postCodeNo=${postCodeNo}&bankCode=${bankCode}&usageCode=${usageCode}&lastCode=${lastCode}&filter=${filter}&type=${type}`;
    return url
}



// 分行數據
// 分行數據 - 分行資訊(電號列表)
export const bankUrl = ({bankCode,account}) => {
    const url = `${apiUrl}getBank?bankCode=${bankCode}&account=${account}`;
    return url
}
// 分行數據 - 電表列表(GET)
export const meterSetupListUrl = ({ bankCode, eco5Account, account }) => {
    const url = `${apiUrl}getMeterSetupList?bankCode=${bankCode}&eco5Account=${eco5Account ? eco5Account : ''}&account=${account}&enabled=1`;
    return url
}
// 分行數據 - 用電資料(當月,上月,去年同期)
export const bankECDateUrl = () => {
    const url = `${apiUrl}getDate`;
    return url
}
// 分行數據 - 用電資料(當月,上月,去年同期)
export const getBankElectricityUrl = ({bankCode,date}) => {
    const url = `${apiUrl}getBankElectricity?bankCode=${bankCode}&date=${date}`;
    return url
}
// 分行數據 - 用電資料(當月圖表)
export const getElectricityNowChartUrl = ({bankCode}) => {
    const url = `${apiUrl}getDemand?bankCode=${bankCode}`;
    return url
}
// 分行數據 - 用電資料(上月,去年同期圖表)
export const getElectricityMonthChartUrl = ({bankCode,date}) => {
    const url = `${apiUrl}getElectricityMonth?bankCode=${bankCode}&date=${date}`;
    return url
}
// 分行數據 - 電費計算
export const totalChargeUrl = ({bankCode,start,end}) => {
    const url = `${apiUrl}getCharge?bankCode=${bankCode}&start=${start}&end=${end}`;
    return url
}
// 分行數據 - 最大需量(分行電號下拉選單)
export const bankPAListUrl = ({bankCode}) => {
    const url = `${apiUrl}getBankPAList?bankCode=${bankCode}`;
    return url
}
// 分行數據 - 最大需量(分行電號最大需量)
export const PAMaxDemandUrl = ({powerAccount,start,end}) => {
    const url = `${apiUrl}getPowerAccountMaxDemand?powerAccount=${powerAccount}&start=${start}&end=${end}`;
    return url
}
// 分行數據 - 最大需量(電表最大需量)
export const MeterMaxDemandUrl = ({ deviceId, start, end }) => {
    const url = `${apiUrl}getPowerRecordMaxDemand?deviceId=${deviceId}&start=${start}&end=${end}`;
    return url
}
// 分行數據 - EUI&EPUI(表格)
export const EUIAndEPUIMonthlyUrl = ({ bankCode, start, end, last, pk, sp, satsp, op }) => {
    const url = `${apiUrl}getEUIAndEPUIMonthly?bankCode=${bankCode}&start=${start}&end=${end}&last=${last}&pk=${pk}&sp=${sp}&satsp=${satsp}&op=${op}`;
    return url
}
// 分行數據 - EUI&EPUI(圖表)
export const EUIAndEPUIChartMonthlyUrl = ({ bankCode, start, end, last, pk, sp, satsp, op }) => {
    const url = `${apiUrl}getEUIAndEPUIChartMonthly?bankCode=${bankCode}&start=${start}&end=${end}&last=${last}&pk=${pk}&sp=${sp}&satsp=${satsp}&op=${op}`;
    return url
}
// 分行數據 - 電能比對圖(圖表)
export const getElectricityChartDailyUrl = ({bankCode,start,end}) => {
    const url = `${apiUrl}getElectricityChartDaily?bankCode=${bankCode}&start=${start}&end=${end}`;
    return url
}
// 分行數據 - 電能比對圖(去年同期圖表)
export const getElectricityChartMonthlyUrl = ({bankCode,start,end}) => {
    const url = `${apiUrl}getElectricityChartMonthly?bankCode=${bankCode}&start=${start}&end=${end}`;
    return url
}
// 分行數據 - 電力趨勢圖(分行)
export const getPowerRecordChartUrl = ({ bankCode, start, end, dateformat }) => {
    const url = `${apiUrl}getPowerRecordChart?bankCode=${bankCode}&dateformat=${dateformat}&start=${start}&end=${end}`;
    return url
}
// 分行數據 - 電力趨勢圖(電表)
export const getPowerRecordMeterChartUrl = ({ deviceId, start, end, dateformat }) => {
    const url = `${apiUrl}getPowerRecordChart?deviceId=${deviceId}&dateformat=${dateformat}&start=${start}&end=${end}`;
    return url
}
// 分行數據 - 電力趨勢圖(選項)
export const getPowerRecordChartListUrl = () => {
    const url = `${apiUrl}getPowerRecordChartList`;
    return url
}
// 分行數據 - 空調用電分析API(GET)
export const bankAirUrl = ({ bankCode, date, pk, sp, satsp, op ,eco5 }) => {
    const url = `${apiUrl}getAir?bankCode=${bankCode}&date=${date}&pk=${pk}&sp=${sp}&satsp=${satsp}&op=${op}&eco5=${eco5}`;
    return url
}




// 電表總覽
export const getMeterUrl = ({deviceId}) => {
    const url = `${apiUrl}getMeter?deviceId=${deviceId}`;
    return url
}
// 電表總覽 - 電表數據API
export const meterElectricityUrl = ({deviceId,date}) => {
	const url = `${apiUrl}getMeterElectricity?deviceId=${deviceId}&date=${date}`;
    return url
}
// 電表總覽 - 電表當月電力資訊API
export const meterElectricityMonthUrl = ({deviceId,date}) => {
	const url = `${apiUrl}getElectricityMonth?deviceId=${deviceId}&date=${date}`;
    return url
}
// 電表總覽 - 電表當月電力ChartAPI
export const meterDemandUrl = ({deviceId}) => {
    const url = `${apiUrl}getDemand?deviceId=${deviceId}`;
    return url
}
// 電表總覽 - 電表電費計算表格API:
export const meterChargeUrl = ({deviceId,start,end}) => {
    const url = `${apiUrl}getMeterCharge?deviceId=${deviceId}&start=${start}&end=${end}`;
    return url
}
// 電表總覽 - (日)電表電能比對圖API
export const meterElectricityChartDailyUrl = ({deviceId,start,end}) => {
    const url = `${apiUrl}getElectricityChartDaily?deviceId=${deviceId}&start=${start}&end=${end}`;
    return url
}
// 電表總覽 - (月)電表電能比對圖API
export const meterElectricityChartMonthlyUrl = ({deviceId,start,end}) => {
    const url = `${apiUrl}getElectricityChartMonthly?deviceId=${deviceId}&start=${start}&end=${end}`;
    return url
}
// 電表總覽 - 電力數值
export const powerRecordUrl = () => {
    const url = `${apiUrl}getPowerRecord`;
    return url
}
// 電表總覽 - 電力數值選項
export const powerRecordListUrl = () => {
    const url = `${apiUrl}getPowerRecordList`;
    return url
}
// 電表總覽 - EUI&EPUI(表格)
export const MeterEUIAndEPUIMonthlyUrl = ({ deviceId, start, end, last, pk, sp, satsp, op }) => {
    const url = `${apiUrl}getEUIAndEPUIMonthly?deviceId=${deviceId}&start=${start}&end=${end}&last=${last}&pk=${pk}&sp=${sp}&satsp=${satsp}&op=${op}`;
    return url
}
// 電表總覽 - EUI&EPUI(圖表)
export const MeterEUIAndEPUIChartMonthlyUrl = ({ deviceId, start, end, last, pk, sp, satsp, op }) => {
    const url = `${apiUrl}getEUIAndEPUIChartMonthly?deviceId=${deviceId}&start=${start}&end=${end}&last=${last}&pk=${pk}&sp=${sp}&satsp=${satsp}&op=${op}`;
    return url
}
// 電表總覽 - 狀態下拉選單API:
export const meterStatusListUrl = () => {
    const url = `${apiUrl}getMeterStatusList`;
    return url
}
// 電表總覽 - 狀態下拉選單API:
export const meterOverviewUrl = ({ city, postCodeNo, bankCode, usageCode, status }) => {
    const url = `${apiUrl}getMeterOverview?city=${city}&postCodeNo=${postCodeNo}&bankCode=${bankCode}&usageCode=${usageCode}&status=${status}`;
    return url
}
// 電表總覽 - 電表報表API
export const meterReportUrl = ({ deviceId, date, type }) => {
    const url = `${apiUrl}getMeterReport?deviceId=${deviceId}&date=${date}&type=${type}`;
    return url
}


// 用電排行
export const electricityRankUrl = ({account,city,postCodeNo,bankCode,usageCode,recType,start,end,filter,type}) => {
    const url = `${apiUrl}getElectricityRank?city=${city}&postCodeNo=${postCodeNo}&bankCode=${bankCode}&usageCode=${usageCode}&recType=${recType}&start=${start}&end=${end}&filter=${filter}&account=${account}&type=${type}`;
    return url
}


// 電費試算
// 最適電費
// 需量最佳化
export const bestCCUrl = ({ city, postCodeNo, bankCode, start, end, mode, account } ) => {
    const url = `${apiUrl}getBestCC?city=${city}&postCodeNo=${postCodeNo}&bankCode=${bankCode}&start=${start}&end=${end}&mode=${mode}&account=${account}`;
    return url
}
// 需量最佳化 - 契約容量最佳化列表
export const bestCCListUrl = ({ PowerAccount, BestRatePlanCode, start, end }) => {
    const url = `${apiUrl}getBestCCList?powerAccount=${PowerAccount}&start=${start}&end=${end}&ratePlanCode=${BestRatePlanCode}`;
    return url
}


// 管理與設定
// 帳號管理
// 帳號管理 - 取得使用者帳號資訊(表格)
export const adminSteupUrl = ({Account,City,PostCodeNo,BankCode,RankCode,type}) => {
    const url = `${apiUrl}getAdminSetup?account=${Account}&city=${City}&postCodeNo=${PostCodeNo}&bankCode=${BankCode}&rankCode=${RankCode}&type=${type}`;
    return url
}
// 帳號管理 - 新增帳號
export const addAdminSetupUrl = () => {
    const url = `${apiUrl}addAdminSetup`;
    return url
}
// 帳號管理 - 更新帳號
export const updAdminSetupUrl = () => {
    const url = `${apiUrl}updAdminSetup`;
    return url
}
// 帳號管理 - 匯入帳號資料API(POST)
export const importAccountUrl = () => {
    const url = `${apiUrl}importAccount`;
    return url
}
// 帳號管理 - 匯入EXCEL-比對資料(POST)
export const importAccountTempUrl = () => {
    const url = `${apiUrl}importAccountTemp`;
    return url
}
// 帳號管理 - 編輯 - 區域名稱(下拉清單)
export const areaListUrl = () => {
    const url = `${apiUrl}getAreaList`;
    return url
}
// 區域管理 - 區域資料(GET)
export const getAreaUrl = () => {
    const url = `${apiUrl}getArea`;
    return url;
}
// 區域管理 - 新增區域(POST)
export const addAreaUrl = () => {
    const url = `${apiUrl}addArea`;
    return url
}
// 區域管理 - 修改區域資料(POST)
export const updAreaUrl = () => {
    const url = `${apiUrl}updArea`;
    return url
}
// 區域管理 - 刪除區域資料(POST)
export const delAreaUrl = () => {
	const url = `${apiUrl}delArea`;
    return url
}



// 分行管理 - 基本資料
export const bankInfoUrl = ({bankCode}) => {
    const url = `${apiUrl}getBankInf?bankCode=${bankCode}`;
    return url
}
// 分行管理 - 新增分行
export const addBankInfoUrl = () => {
    const url = `${apiUrl}addBankInf`;
    return url
}
// 分行管理 - 編輯分行
export const updBankInfoUrl = () => {
    const url = `${apiUrl}updBankInf`;
    return url
}
// 分行管理 - 電號資訊(電號列表)
export const PowerAccountListUrl = ({bankCode}) => {
    const url = `${apiUrl}getPowerAccount?bankCode=${bankCode}`;
    return url
}
// 分行管理 - 電號資訊
export const PowerAccountUrl = ({bankCode,powerAccount,retCount}) => {
    const url = `${apiUrl}getPowerAccount?bankCode=${bankCode}&powerAccount=${powerAccount}&retCount=${retCount}`;
    return url
}
// 分行管理 - 電號資訊(新增電號)
export const addPowerAccountUrl = () => {
    const url = `${apiUrl}addPowerAccount`;
    return url;
}
// 分行管理 - 電號資訊(編輯電號)
export const updPowerAccountUrl = () => {
    const url = `${apiUrl}updPowerAccount`;
    return url
}
// 分行管理 - 電號資訊(變更電號名稱)
export const fixPowerAccountUrl = () => {
    const url = `${apiUrl}fixPowerAccount`;
    return url
}
// 分行管理 - 電號資訊(刪除電號)
export const delPowerAccountUrl = () => {
    const url = `${apiUrl}delPowerAccount`;
    return url
}
// 分行管理 - 電號所有歷史契約容量
export const historyCCListUrl = ({bankCode,powerAccount}) => {
    const url = `${apiUrl}getPowerAccount?bankCode=${bankCode}&powerAccount=${powerAccount}`;
    return url
}

// 事件紀錄(暫無)
// 電費單價設定
// 電費單價設定 - 依用電種類取得年份清單
export const electricityPriceUrl = ({ratePlanCode}) => {
    const url = `${apiUrl}getElectricityPrice?ratePlanCode=${ratePlanCode}`;
    return url
}
// 電費單價設定 - 指定年月電費單價資訊(表格資訊)
export const electricityPriceInfoUrl = ({ratePlanCode,year,month}) => {
    const url = `${apiUrl}getElectricityPrice?ratePlanCode=${ratePlanCode}&year=${year}&month=${month}`;
    return url
}
// 電費單價設定 - 新增電費單價資訊(表格資訊)
export const addElectricityPriceUrl = () => {
    const url = `${apiUrl}addElectricityPrice`;
    return url
}
// 電費單價設定 - 更新電費單價資訊(表格資訊)
export const updElectricityPriceUrl = () => {
    const url = `${apiUrl}updElectricityPrice`;
    return url
}
// 電費單價設定 - 目前年份清單
export const yearListUrl = () => {
    const url = `${apiUrl}getYearList`;
    return url
}
// 電費單價設定 - 目前月份清單
export const monthListUrl = () => {
    const url = `${apiUrl}getMonthList`;
    return url
}
// ECO-5設定
// ECO-5設定 - ECO-5列表&帳號資訊
export const getECO5Url = ({bankCode,eco5Account}) => {
    const url = `${apiUrl}getControllerSetup?bankCode=${bankCode}&eco5Account=${eco5Account}`;
    return url
}
// ECO-5設定 - 新增ECO5帳號資訊
export const addECO5InfoUrl = () => {
    const url = `${apiUrl}addControllerSetup`;
    return url
}
// ECO-5設定 - 編輯ECO5帳號資訊
export const updECO5InfoUrl = () => {
    const url = `${apiUrl}updControllerSetup`;
    return url;
}
// ECO-5設定 - 電表列表(啟用:1,非啟用:0)
export const ECO5MeterListUrl = ({bankCode,eco5Account,enabled}) => {
    const url = `${apiUrl}getMeterSetupList?bankCode=${bankCode}&eco5Account=${eco5Account}&enabled=${enabled}`;
    return url
}
// ECO-5設定 - (單)電表詳細資訊
export const ECO5MeterInfoUrl = ({deviceId,eco5Account,enabled}) => {
    const url = `${apiUrl}getMeterSetup?deviceId=${deviceId}&eco5Account=${eco5Account}&enabled=${enabled}`;
    return url
}
// ECO-5設定 - (單)電表詳細資訊 - 電表列表
export const ECO5PAListUrl = ({bankCode}) => {
    const url = `${apiUrl}getPowerAccountList?bankCode=${bankCode}`;
    return url
}
// ECO-5設定 - 編輯(單)電表詳細資訊
export const updECO5MeterInfoUrl = () => {
    const url = `${apiUrl}updMeterSetup`;
    return url
}
// ECO-5設定 - 新增(單)電表詳細資訊
export const addECO5MeterInfoUrl = () => {
    const url = `${apiUrl}addMeterSetup`;
    return url
}
// ECO-5設定 - 重新統計ECO5電表
export const repriceMeterUrl = () => {
    const url = `${apiUrl}repriceMeter`;
    return url
}
// 電費單輸入
// 電費單輸入 - 電費單資料API(Get):
export const getEntryBill = ({powerAccount,billMon}) => {
    const url = `${apiUrl}getEntryBill?powerAccount=${powerAccount}&billMon=${billMon}`;
    return url
}
// 電費單輸入 - 新增電費單API(POST)
export const addEntryBillUrl = () => {
    const url = `${apiUrl}addEntryBill`;
    return url
}
// 電費單輸入 - 修改電費單API(POST)
export const updEntryBillUrl = () => {
    const url = `${apiUrl}updEntryBill`;
    return url
}
// 電費單輸入 - 電費單列表API(Get):
export const getEntryBillListUrl = ({powerAccount}) => {
    const url = `${apiUrl}getEntryBillList?powerAccount=${powerAccount}`;
    return url
}
// 電費單輸入 - 電費單列表API(Get):
// 目標值設定
// 目標值設定 - 取得目前目標值內容
export const KPISetupUrl = () => {
    const url = `${apiUrl}getKPISetup`;
    return url
}
// 目標值設定 - 修改目標值(POST)
export const updKPIUrl = () => {
    const url = `${apiUrl}updKPISetup`;
    return url
}



// 清單
// 縣市群組清單(六都加其他)
export const cityGroupListUrl = () => {
    const url = `${apiUrl}getCityGroupList`;
    return url
}
// 縣市清單(下拉) - 僅包含現有分行縣市
export const cityListUrl = ({cityGroup,account}) => {
    const url = `${apiUrl}getCityList?cityGroup=${cityGroup}&account=${account}`;
    return url
}
// 縣市清單(下拉) - 全台縣市
export const allCityListUrl = () => {
    const url = `${apiUrl}getCityList?cityGroup=&type=all`;
    return url
}
// 縣市清單(下拉) - 排除六都外所有，有分行縣市
export const otherCityListUrl = () => {
    const url = `${apiUrl}getCityList?cityGroup=其他`;
    return url
}
// 區域清單(下拉)
export const distListUrl = ({city,account,type}) => {
    const url = `${apiUrl}getDistList?city=${city}&account=${account}&type=${type}`
    return url
}
// 分行清單(下拉)
export const bankListUrl = ({city,postCodeNo,account}) => {
    const url = `${apiUrl}getBankList?city=${city}&postCodeNo=${postCodeNo}&account=${account}`;
    return url
}
// KPI清單
export const KPIListUrl = () => {
    const url = `${apiUrl}getKPI`;
    return url
}
// DF清單 (混合式,浮動式,固定式,平均式,...)(下拉)
export const DFListUrl = () => {
    const url = `${apiUrl}getDFList`;
    return url
}
// 電表類型(下拉)
export const meterTypeListUrl = () => {
    const url=`${apiUrl}getMeterTypeList`;
    return url
}
// 電號類型(下拉)
export const PATypeListUrl = () => {
    const url=`${apiUrl}getPATypeList`;
    return url
}
// 權限等級清單(總行管理員,總行使用者,...)(下拉)
export const rankListUrl = ({account}) => {
    const url = `${apiUrl}getRankListEdit?account=${account}`;
    return url
}
// 用電種類(下拉)
export const ratePlanListUrl = () => {
    const url=`${apiUrl}getRatePlanList`;
    return url
}
// 用電種類(下拉)(上限)
export const ratePlanLimitListUrl = ({limit}) => {
    const url = `${apiUrl}getRatePlanList?limit=9`;
    return url
}
// 耗能分類(下拉)
export const usageListUrl = () => {
    const url = `${apiUrl}getUsageList`;
    return url
}
// 接線方式(下拉)
export const wiringListUrl = () => {
    const url=`${apiUrl}getWiringList`;
    return url;
}

