export const CHANGE_CITY = 'CHANGE_CITY'; 
export const CHANGE_DIST = 'CHANGE_DIST'; 
export const CHANGE_BANK = 'CHANGE_BANK';

export const FETCH_CITYGROUP_BEGIN = 'FETCH_CITYGROUP_BEGIN';
export const FETCH_CITYGROUP_SUCCESS = 'FETCH_CITYGROUP_SUCCESS';
export const FETCH_CITYGROUP_ERROR = 'FETCH_CITYGROUP_ERROR';
// 僅包含現有分行縣市
export const FETCH_CITY_BEGIN = 'FETCH_CITY_BEGIN';
export const FETCH_CITY_SUCCESS = 'FETCH_CITY_SUCCESS';
export const FETCH_CITY_ERROR = 'FETCH_CITY_ERROR';
// 全台
export const FETCH_ALLCITY_BEGIN = 'FETCH_ALLCITY_BEGIN';
export const FETCH_ALLCITY_SUCCESS = 'FETCH_ALLCITY_SUCCESS';
export const FETCH_ALLCITY_ERROR = 'FETCH_ALLCITY_ERROR';

export const FETCH_CITYOTHER_BEGIN = 'FETCH_CITYOTHER_BEGIN';
export const FETCH_CITYOTHER_SUCCESS = 'FETCH_CITYOTHER_SUCCESS';
export const FETCH_CITYOTHER_ERROR = 'FETCH_CITYOTHER_ERROR';
