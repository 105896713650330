import {call,put,takeLatest} from 'redux-saga/effects';
import {
    FETCH_PATYPELIST_BEGIN,
    FETCH_PATYPELIST_SUCCESS,
    FETCH_PATYPELIST_ERROR,
} from '../constants/PATypeList-action-type';
import { PATypeListUrl } from '../utils/apiList';


export const fetchPATypeListBegin = (token) =>({
    type: FETCH_PATYPELIST_BEGIN,
    payload: {
        token: token,
        PATypeList: [],
        PATypeListError: '',
    }
})

export const fetchPATypeListSuccess = (data) =>({
    type: FETCH_PATYPELIST_SUCCESS,
    payload: {
        PATypeList: data,
        PATypeListError: '',
    }
})

export const fetchPATypeListError = (error) =>({
    type: FETCH_PATYPELIST_ERROR,
    payload: {
        PATypeList: [],
        PATypeListError: error,
    }
})

function* fetchPATypeList(obj) {
    const token = obj.payload.token;

    try{
        const url=PATypeListUrl();
        const data = yield call(
            () => fetch(url,{
                method: 'GET',
                headers: new Headers({
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'token': token,
                })
            })
            .then( response => response.json())
        )

        if( data.code === '00' && data.msg) {
            yield put(fetchPATypeListSuccess(data.msg.List))
        }else {
            yield put(fetchPATypeListError(data.msg))
        }
    }catch(error){
        yield put(fetchPATypeListError(error))
    }
}

export function* PATypeListSaga() {
    yield takeLatest('FETCH_PATYPELIST_BEGIN',fetchPATypeList)
}