import {
    FETCH_PATYPELIST_BEGIN,
    FETCH_PATYPELIST_SUCCESS,
    FETCH_PATYPELIST_ERROR,
} from '../constants/PATypeList-action-type';
// 初始化
const initialState = {
    // token: '',
    PATypeList: [],
    PATypeListError: '',
}

const PATypeListReducer = ( state = initialState , action) => {
    switch(action.type){
        case FETCH_PATYPELIST_BEGIN:
            return {
                ...state,
                // token: action.payload.token,
            }
        case FETCH_PATYPELIST_SUCCESS:
            return {
                ...state,
                PATypeList: action.payload.PATypeList,
                PATypeListError: ''
            }
        case FETCH_PATYPELIST_ERROR:
            return {
                ...state,
                PATypeList: [],
                PATypeListError: action.payload.PATypeListError,
            }
        default:
            return state
    }
}
export default PATypeListReducer;