import { call, put, takeLatest } from 'redux-saga/effects';
import {
    SUBMIT,
    EXIT,
    CHANGE_ACCOUNT,
    CHANGE_PASSWORD,
    FETCH_DATA_BEGIN,
    FETCH_DATA_SUCCESS,
    FETCH_DATA_ERROR,
} from '../constants/Login-action-type';
import { authUrl } from '../utils/apiList';
import { setBankCode } from './BankAction';

// api
export const submit = (data) => {
    return {
        type: SUBMIT,
        payload: {
            redirectToReferrer: true,
        }
    }
}

export const exit = () => ({
    type: EXIT,
    palyoad: {
        redirectToReferrer: false,
        token: '',
        username: '',
        roleId: '',
        company: '',
        functionList: '',
        SystemId: '',
        error: '',
        data: '',
    }
})

// 更新account Input內容放入Store
export const updateAccount = (inputVal) => ({
    type: CHANGE_ACCOUNT,
    payload: {
        account: inputVal,
    }
})

// 更新passWord Input內容放入Store
export const updatePassWord = (inputVal) => ({
    type: CHANGE_PASSWORD,
    payload: {
        password: inputVal,
    }
})


// submit data放入Store
export const fetchDataBegin = (obj) => ({
    type: FETCH_DATA_BEGIN,
    payload: {
        account: obj.account,
        password: obj.password
    }
});

export const fetchDataSuccess = data => {
    return {
        type: FETCH_DATA_SUCCESS,
        payload: {
            // Account: data.msg.Account,
            token: data.msg.Account.Token,
            username: data.msg.Account.UserName,
            roleId: data.msg.Account.RoleId,
            company: data.msg.Company,
            functionList: data.msg.FunctionList,
            SystemId: data.msg.SystemId,
            perPage: 10,
            error: '',
            data: data,
        }
    }
}

export const fetchDataError = (data) => ({
    type: FETCH_DATA_ERROR,
    payload: {
        error: data.msg
    }
});

function* login_fetchData(userObj) {
    // 使用 data 接收請求的資料
    try {
        const url = authUrl();
        const data = yield call(
            () => fetch(url, {
                method: 'POST',
                headers: new Headers({
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'account': userObj.payload.account,
                    'pw': userObj.payload.password
                })
            }).then(response => response.json())
        );
        if (data.code === '00') {
            yield put(fetchDataSuccess(data));
            yield put(setBankCode(data?.msg?.BankCode ? data.msg.BankCode : ''));            
            yield put(submit());
        } else {
            yield put(fetchDataError(data));
        }
    }
    catch (error) {
        yield put(fetchDataError());
    }

}


export function* loginSaga() {
    yield takeLatest(FETCH_DATA_BEGIN, login_fetchData);
}