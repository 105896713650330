import {call,put,takeLatest} from 'redux-saga/effects';
import {
    FETCH_METERTYPELIST_BEGIN,
    FETCH_METERTYPELIST_SUCCESS,
    FETCH_METERTYPELIST_ERROR,
} from '../constants/MeterTypeList-action-type';
import { meterTypeListUrl } from '../utils/apiList';


export const fetchMeterTypeListBegin = (token) =>({
    type: FETCH_METERTYPELIST_BEGIN,
    payload: {
        token: token,
        meterTypeList: [],
        meterTypeListError: '',
    }
})

export const fetchMeterTypeListSuccess = (data) =>({
    type: FETCH_METERTYPELIST_SUCCESS,
    payload: {
        meterTypeList: data,
        meterTypeListError: '',
    }
})

export const fetchMeterTypeListError = (error) =>({
    type: FETCH_METERTYPELIST_ERROR,
    payload: {
        meterTypeList: [],
        meterTypeListError: error,
    }
})

function* fetchMeterTypeList(obj){
    const token = obj.payload.token;

    try{
        const url=meterTypeListUrl();
        const data = yield call(
            () => fetch(url,{
                method: 'GET',
                headers: new Headers({
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'token': token,
                })
            })
            .then( response => response.json())
        )

        if( data.code === '00' && data.msg) {
            yield put(fetchMeterTypeListSuccess(data.msg.List))
        }else {
            yield put(fetchMeterTypeListError(data.msg))
        }
    }catch(error){
        yield put(fetchMeterTypeListError(error))
    }
}

export function* MeterTypeListSaga() {
    yield takeLatest('FETCH_METERTYPELIST_BEGIN',fetchMeterTypeList)
}