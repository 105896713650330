export const INIT_ACCOUNT_TABLE = 'INIT_ACCOUNT_TABLE';
export const INIT_ACCOUNT_INFO = 'INIT_ACCOUNT_INFO';

export const CHANGE_ACCOUNT_INFO = 'CHANGE_ACCOUNT_INFO';
export const CHANGE_RESULTTABLE = 'CHANGE_RESULTTABLE';

export const FETCH_ACCOUNT_BEGIN = 'FETCH_ACCOUNT_BEGIN';
export const FETCH_ACCOUNT_SUCCESS = 'FETCH_ACCOUNT_SUCCESS';
export const FETCH_ACCOUNT_ERROR = 'FETCH_ACCOUNT_ERROR';

export const RESET_ACCOUNT = 'RESET_ACCOUNT';   //清空帳號管理資訊