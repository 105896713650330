import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchDataBegin,updateAccount } from '../actions/LoginAction';
import { applyAccountUrl, forgetPasswordUrl, checkVerifyCodeUrl, setupPasswordUrl } from '../utils/apiList';
import LoginContent from './LoginContent';  // 登入
import ApplyContent from './ApplyContent'; // 帳號開通&忘記密碼
import Footer from '../components/Footer';
import CusTab from '../components/CusTab/';
import './Login.css';
import ajax from '../utils/ajax';
import classNames from 'classnames';



class Login extends Component {
    constructor(props){
        super(props)
        this.state = {
            loaded: false,
            // applyAccount
            applyAccountLoading: false,
            applyAccountEmail: '',
            applyAccount: {
                Step: '1',
                Account: '',
                UserName: '',
                VerifyCode: '',
                Step3Account: '',
                Password:'',
                PasswordCheck:'', //再次輸入
                isOpen: false,
                message: '',
            },
            // forgetPassword
            forgetPasswordLoading: false,
            forgetPasswordEmail: '',
            forgetPassword: {
                Step: '1',
                Account: '',
                UserName: '',
                VerifyCode: '',
                Step3Account: '',
                Password:'',
                PasswordCheck:'', //再次輸入
                isOpen: false,
                message: '',
            }
        }
    }

    componentDidMount() {
        if(this.state.loaded === false) {
            this.setState({loaded: true})
        }
    }

    render() {
        const { loaded, applyAccountLoading, applyAccountEmail, applyAccount, forgetPasswordLoading, forgetPasswordEmail, forgetPassword, } = this.state;
        const isLoading = classNames({
            'loading': loaded === false
        })
        if (this.props.token !== '' && this.props.redirectToReferrer) { return <Redirect to='/' /> }
        return (
            <>
            <div className={`login__container ${isLoading}`}>
                <div className='login'>
                    <div className='login__center'>
                        <h2 className="title">雲端智慧能源管理系統</h2>
                        <h6 className="subTitle">ECO-Cloud Smart Energy Management System</h6>
                        <CusTab clickEvent={this.resetContent}>
                            <div name="登入" className="w-100 text-center">
                                <LoginContent />
                            </div>
                            
                            <div name="帳號開通">
                                <ApplyContent
                                    loading={applyAccountLoading}
                                    id="ApplyAccount"
                                    content={applyAccount}
                                    email={applyAccountEmail}
                                    changeInputValue={this.changeApplyContentInput}
                                    getApplyEmail={this.getApplyAccountEmail}
                                    getCheckVerifyCode={this.getCheckVerifyCode}
                                    hadleChangePassword={this.getSetupPassword}
                                    handleCancel={()=>{this.resetContent(1)}}
                                />
                            </div>

                            <div name="忘記密碼">
                                <ApplyContent
                                    loading={forgetPasswordLoading}
                                    id="ForgetPassword"
                                    email={forgetPasswordEmail}
                                    content={forgetPassword}
                                    changeInputValue={this.changeApplyContentInput}
                                    getApplyEmail={this.getForgetPassword}
                                    getCheckVerifyCode={this.getCheckVerifyCode}
                                    hadleChangePassword={this.getSetupPassword}
                                    handleCancel={()=>{this.resetContent(2)}}
                                />
                            </div>
                        </CusTab>
                       
                    </div>
                </div>
                <Footer />
            </div>
            </>
        )
    }


    // 清空點擊頁籤內容
    resetContent = (idx) => {
        // 0 登入
        if( idx === 1) {// 1 帳號開通
            this.setState({
                applyAccountEmail: '',
                applyAccount: {
                    Step: '1',
                    Account: '',
                    UserName: '',
                    VerifyCode: '',
                    Step3Account: '',
                    Password:'',
                    PasswordCheck:'', //再次輸入
                    isOpen: false,
                    message: '',
                },
            })
        }
        else if (idx === 2) {// 2 忘記密碼
            this.setState({
                forgetPasswordEmail: '',
                forgetPassword: {
                    Step: '1',
                    Account: '',
                    UserName: '',
                    VerifyCode: '',
                    Step3Account: '',
                    Password:'',
                    PasswordCheck:'', //再次輸入
                    isOpen: false,
                    message: '',
                },
            })
        }
    }
    // 變更ApplyContent Input內容
    changeApplyContentInput = (obj) => {
        const { id,name, value } = obj;
        if(id === 'ApplyAccount') {
            const { Step, VerifyCode } = this.state.applyAccount;
            if(Step === '2' && VerifyCode !== '') {
                this.setState({applyAccountLoading: false})
            }
            this.setState((prevState) => ({
                applyAccountLoading: false,
                applyAccount: {
                    ...prevState.applyAccount,
                    [name]:value,
                    isOpen: false,
                    message: '',
                    // loading: false,
                }
            }))
        }
        else if (id === 'ForgetPassword') {
            const { Step, VerifyCode } = this.state.forgetPassword;
            if(Step === '2' && VerifyCode !== '') {
                this.setState({forgetPasswordLoading: false})
            }
            this.setState((prevState) => ({
                forgetPasswordLoading: false,
                forgetPassword: {
                    ...prevState.forgetPassword,
                    [name]:value,
                    isOpen: false,
                    message: '',
                    // loading: false,
                }
            }))
        }
    }
    // step1
    // 帳號申請API(POST):
    getApplyAccountEmail = (obj) => {
        const { Account, UserName } = obj
        const postData = {
            Account:Account,
            UserName:UserName,
        }
        this.setState({applyAccountLoading: true,})
        this.fetchApplyAccount(postData).then(response => {
            if(response.code === '00') {
                this.setState((prevState) => ({
                    applyAccountLoading: true,
                    applyAccountEmail: response.msg.Email,
                    applyAccount: {
                        ...prevState.applyAccount,
                        Step: '2',
                        VerifyCode: '',
                        isOpen: false,
                        message: '',
                    }
                }))
            }
            else{
                this.setState((prevState) => ({
                    applyAccountLoading: false,
                    applyAccount: {
                        ...prevState.applyAccount,
                        Step: '1',
                        isOpen: true,
                        message: response.msg,
                    }
                }))
            }
        })
    }
    fetchApplyAccount = (postData) => {
        const token = '';
        const url = applyAccountUrl();
        this.setState({applyAccountLoading: true})
        return ajax(url,"POST",token,postData)
    }
    // 忘記密碼API(POST):
    getForgetPassword = async(obj) => {
        const { Account, UserName } = obj
        const postData = {
            Account:Account,
            UserName:UserName,
        }
        await this.setState({forgetPasswordLoading: true})
        await this.fetchForgetPassword(postData).then(response => {
            if(response.code === '00') {
                this.setState((prevState) => ({
                    forgetPasswordLoading: true,
                    forgetPasswordEmail: response.msg.Email,
                    forgetPassword: {
                        ...prevState.forgetPassword,
                        Step: '2',
                        VerifyCode: '',
                        isOpen: false,
                        message: '',
                    }
                }))
            }
            else{ 
                this.setState((prevState) => ({
                    forgetPasswordLoading: false,
                    forgetPassword: {
                        ...prevState.forgetPassword,
                        Step: '1',
                        isOpen: true,
                        message: response.msg,
                    }
                }))
            }
        })
    }
    fetchForgetPassword = (postData) => {
        const token = '';
        const url = forgetPasswordUrl();
        this.setState({forgetPasswordLoading: true})
        return ajax(url,"POST",token,postData)
    }
    // step2
    // 檢核驗證碼API(POST):
    getCheckVerifyCode = async(obj) => {
        const { id , VerifyCode, Account } = obj
        const postData = {
            Account: Account,
            VerifyCode:VerifyCode
        }
        if(id === 'ApplyAccount') {}
        else if (id === 'ForgetPassword') {}

        await this.fetchCheckVerifyCode(postData).then(response => {
            if(response.code === '00') {
                if(id === 'ApplyAccount') {
                    this.setState({applyAccountLoading: true})
                    this.setState((prevState) => ({
                        applyAccount: {
                            ...prevState.applyAccount,
                            Step: '3',
                            Step3Account:Account,
                            Password: '',
                            PasswordCheck: '',
                            isOpen: false,
                            message: '',
                        }
                    }))
                    
                }
                else if (id === 'ForgetPassword') {
                    this.setState((prevState) => ({
                        forgetPassword: {
                            ...prevState.forgetPassword,
                            Step: '3',
                            Step3Account:Account,
                            Password: '',
                            PasswordCheck: '',
                            isOpen: false,
                            message: '',
                        }
                    }))
                    this.setState({forgetPasswordLoading: false})
                }
            } else {
                if(id === 'ApplyAccount') {
                    this.setState((prevState) => ({
                        applyAccount: {
                            ...prevState.applyAccount,
                            Step: '2',
                            Step3Account:Account,
                            Password: '',
                            PasswordCheck: '',
                            isOpen: true,
                            message: response.msg,
                        }
                    }))
                    this.setState({applyAccountLoading: false})
                }
                else if (id === 'ForgetPassword') {
                    this.setState((prevState) => ({
                        forgetPassword: {
                            ...prevState.forgetPassword,
                            Step: '2',
                            Step3Account:Account,
                            Password: '',
                            PasswordCheck: '',
                            isOpen: true,
                            message: response.msg,
                        }
                    }))
                    this.setState({forgetPasswordLoading: false})
                }
            }
        })
    }
    fetchCheckVerifyCode = (postData) => {
        const { token } = this.props;
        const url = checkVerifyCodeUrl();
        this.setState({applyAccountLoading: true, forgetPasswordLoading: true})
        return ajax(url,'POST',token,postData);
    }
    // step3
    // 設定密碼API(POST):
    getSetupPassword = (obj) => {
        const { id , Account, Password, PasswordCheck } = obj;
        const postData = {Account,Password,PasswordCheck};
        this.fetchSetupPassword(postData).then(response => {
            if(response.code === '00') {
                if(id === 'ApplyAccount') {
                    this.setState((prevState) => ({
                        applyAccountLoading: false,
                        forgetPasswordLoading: false,
                        applyAccount: {
                            ...prevState.applyAccount,
                            Step: '4',
                            isOpen: true,
                            message: response.msg,
                        }
                    }))
                }
                else if (id === 'ForgetPassword') {
                    this.resetContent(2);
                    this.setState((prevState) => ({
                        applyAccountLoading: false,
                        forgetPasswordLoading: false,
                        forgetPassword: {
                            ...prevState.forgetPassword,
                            Step: '4',
                            isOpen: true,
                            message: response.msg,
                        }
                    }))
                }

                // 申請成功,自動登入
                this.props.updateAccount(Account);
                this.props.fetchDataBegin({account:Account,password:Password})
            }else {
                if(id === 'ApplyAccount') {
                    this.setState((prevState) => ({
                        applyAccountLoading: false,
                        forgetPasswordLoading: false,
                        applyAccount: {
                            ...prevState.applyAccount,
                            Step: '3',
                            isOpen: true,
                            message: response.msg,
                        }
                    }))
                }
                else if (id === 'ForgetPassword') {
                    this.setState((prevState) => ({
                        applyAccountLoading: false,
                        forgetPasswordLoading: false,
                        forgetPassword: {
                            ...prevState.forgetPassword,
                            Step: '3',
                            isOpen: true,
                            message: response.msg,
                        }
                    }))
                }
            }
        })
    }
    fetchSetupPassword = (postData) => {
        const { token } = this.props;
        const url = setupPasswordUrl();
        this.setState({applyAccountLoading: true, forgetPasswordLoading: true})
        return ajax(url,'POST',token,postData);
    }

}

Login.propTypes = {
    account: PropTypes.string,
    password: PropTypes.string,
    updateAccount: PropTypes.func,
    updatePassWord: PropTypes.func,
    submit: PropTypes.func,

}

const mapStateToProps = (state, ownProps) => {
    return {
        account: state.loginReducer.account,
        password: state.loginReducer.password,
        token: state.loginReducer.token,
        error: state.loginReducer.error,
        // username: state.loginReducer.username, 
        redirectToReferrer: state.loginReducer.redirectToReferrer,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchDataBegin: (userObj) => dispatch(fetchDataBegin(userObj)),
        updateAccount: (value) => dispatch(updateAccount(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)


