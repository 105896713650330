import {
    FETCH_METERTYPELIST_BEGIN,
    FETCH_METERTYPELIST_SUCCESS,
    FETCH_METERTYPELIST_ERROR,
} from '../constants/MeterTypeList-action-type';
// 初始化
const initialState = {
    // token:'',
    meterTypeList: [],
    meterTypeListError:''
}
const MeterTypeListReducer = ( state = initialState , action ) => {
    switch(action.type) {
        case FETCH_METERTYPELIST_BEGIN: 
            return {
                ...state,
                // token: action.payload.token,
            }
        case FETCH_METERTYPELIST_SUCCESS:
            return {
                ...state,
                meterTypeList: action.payload.meterTypeList,
                meterTypeListError:''
            }
        case FETCH_METERTYPELIST_ERROR:
            return {
                ...state,
                meterTypeList: [],
                meterTypeListError: action.payload.meterTypeListError
            }
        default:
            return state
    }
}
export default MeterTypeListReducer;
