/* Export File */
/* Export EXCEL,PDF,.... */
// 輸出Excel
export const exportExcel = async (token, exportURL, excelName, data) => {
    await fetch(exportURL, {
        method: 'POST',
        headers: new Headers({
            'Accept': '*/*',
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'token': token,
        }),
        body: JSON.stringify(data)
    })
        .then(response => response.blob())
        .then(response => {
            const url = window.URL.createObjectURL(response);
            
            var a = document.createElement("a");
            a.href = url;
            a.setAttribute('download', excelName);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(response);
        })
}

/* Table.js */
/* Table file contains functions to help you when working with tables */




/* Utils.js */
/* This file contains functions you can use anywhere in your application */


// 頁面按鈕權限
export const checkPageBtnRole = (functionList) => {
    const href = '/' + window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    const target = functionList.filter((item, index) => item.Url === href)
    const list = target[0].Button;
    return list
}





// api 導址
export const location = () => {
    const loc = window.location.href;
    const path = window.location.origin;
    // console.log('path',path)
    if (loc.indexOf('localhost:3000') >= 0) {
        // 開發用localhost :3000
        return 'https://www.gtething.tw/cathaybkeco/'
    }
    else if (loc.indexOf('localhost') >= 0) {
        // Docker開發用localhost :8085
        return 'http://localhost:8085/cathaybkeco/'
    }
    else {
        return `${path}/cathaybkeco/`
    }
}