import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkFunctionList } from './utils/checkFunctionList';
import { 
  Dashboard, DashboardRank, 
  Bank, Meter, MeterList, 
  ElectricityRank, 
  BestRatePlan, BestCC, 
  AccountManage, AreaManage, 
  BankManage, 
  EventRecord, 
  PriceOfElectricity, 
  ECO5Setting, 
  SharedElectricityFee, 
  Kpi
} from './Route/lazyLoad';
import PrivacyPolicy from './components/Footer/PrivacyPolicy';

import './style/App.scss';
import Toolbar from './components/header/Toolbar/Toolbar';
import SideDrawer from './components/header/SideDrawer/SideDrawer';
import Backdrop from './components/header/Backdrop/Backdrop';
import Login from './login/Login';
import MsgBox from './components/message/MsgBox';
import Footer from './components/Footer';





class App extends Component {
  componentDidMount() { }

  render() {
    const { functionList,roleId } = this.props;
    const dashBoard = functionList?.filter(item => item.Type === "title");
		// 判斷使用者是否可以觀看此頁面
		const currentHash = window.location.hash.replace('#','');
		const checkPage = checkFunctionList(functionList,currentHash,roleId);


    if (this.props.token === '' && !this.props.redirectToReferrer) {
      return <Redirect to='/login' />
    }
    if(checkPage) {
      return (
        <Fragment>
          <Router>
            <div className="app">
              <Toolbar />
              {/* 上方Header畫面 */}
              <SideDrawer />
              {/* 左邊Header畫面 */}
              {this.props.sideDrawerOpen ? <Backdrop /> : ''}
              {/* 灰色背景畫面 */}
              <main className='container-fuild' >
                <Switch>
                  {/* Dashboard */}
                  <Route path='/' exact component={dashBoard?.length > 0 && dashBoard[0].Url === '/' ? Dashboard : Bank} />
                  {/* Dashboard-排行模式 */}
                  <Route path='/rank' component={DashboardRank} />
                  {/* 分行數據 */}
                  <Route path="/bank" component={Bank} />
                  {/* 電表數據 */}
                  <Route path="/meter" component={Meter} />
                  {/* 電表數據 */}
                  <Route path="/meterlist" component={MeterList} />
                  {/* 用電排行 */}
                  <Route path="/electricityRank" component={ElectricityRank} />
                  {/* 最適電費 */}
                  <Route path='/BestRatePlan' component={BestRatePlan} />
                  {/* 需量最佳化 */}
                  <Route path='/BestCC' component={BestCC} />
                  {/* 管理與設定 */}
                  <Route path='/accountManage' component={AccountManage} />{/* 帳號管理 */}
                  <Route path='/areaManage' component={AreaManage} />{/* 區域管理 */}
                  <Route path='/bankManage' component={BankManage} />{/* 分行管理 */}
                  <Route path='/eventRecord' component={EventRecord} />{/* 分行列表 */}
                  <Route path='/priceOfElectricity' component={PriceOfElectricity} />{/* 電費單價設定 */}
                  <Route path='/ECO5Setting' component={ECO5Setting} />{/* ECO-5 輸入 */}
                  <Route path='/sharedElectricityfee' component={SharedElectricityFee} />{/* 電費單輸入 */}
                  <Route path='/kpi' component={Kpi} />{/* 目標值輸入 */}
                  {/* 隱私權政策及網站安全政策 */}
                  <Route path='/PrivacyPolicy' component={PrivacyPolicy} />
                  {/* 登入 */}
                  <Route component={Login} />
                </Switch>
              </main>
              {/* Footer */}
              <Footer />
            </div>
            <MsgBox />
          </Router>
        </Fragment>
      )
    }else {
      return <Redirect to='/' />
    }
  }

  getChildContext() {
    return {
      getChkPrivilegeHandler: (ChkHtmlPrivilege) => this.getChkPrivilegeHandler(ChkHtmlPrivilege)
    }
  }

  getChkPrivilegeHandler(ChkHtmlPrivilege) {
    const { username, token } = this.props;
    const senddata = 'token=' + token + '&username=' + username;
    return fetch(ChkHtmlPrivilege + '?' + senddata, {
      method: 'GET',
    }).then(response => response.json())
  }
}

App.childContextTypes = {
  getChkPrivilegeHandler: PropTypes.func
}

App.propTypes = {
  sideDrawerOpen: PropTypes.bool.isRequired,
  drawerToggleClickToolbar: PropTypes.func,
  backdropClickHandler: PropTypes.func,
  functionList: PropTypes.array,
}


const mapStateToProps = (state, ownProps) => {
  return {
    redirectToReferrer: state.loginReducer.redirectToReferrer,
    sideDrawerOpen: state.appReducer.sideDrawerOpen,
    time: state.appReducer.time,
    isAlert: state.alertReducer.isAlert,
    token: state.loginReducer.token,
    username: state.loginReducer.username,
    roleId: state.loginReducer.roleId,
    functionList: state.loginReducer.functionList,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(App);


