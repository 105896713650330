import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import './DrawerToggleButton.scss';
import { sideDrawer_open, sideDrawer_close } from '../../../../actions/AppAction';

class DrawerToggleButton extends Component {
    render() {
        return (
            <CSSTransition
                in={this.props.sideDrawerOpen}
                classNames='toggle-button-cssTransition'
                timeout={800}
            >
                <div className='toggle-button' onClick={() => this.drawerToggleClickToolbar()}>
                    <span className="toggle-button_frame top">
                        <span className='toggle-button_line'></span>
                    </span>
                    <span className="toggle-button_frame center">
                        <span className='toggle-button_line'></span>
                    </span>
                    <span className="toggle-button_frame bottom">
                        <span className='toggle-button_line'></span>
                    </span>
                </div>
            </CSSTransition>
        )
    }

    drawerToggleClickToolbar() {
        if (!this.props.sideDrawerOpen) {
            this.props.drawerToggleClickToolbar();
        } else {
            this.props.backdropClickHandler();
        }
    }
}

DrawerToggleButton.propTypes = {
    sideDrawerOpen: PropTypes.bool,
    drawerToggleClickToolbar: PropTypes.func,
    backdropClickHandler: PropTypes.func,
}

const mapStateToProps = (state) => ({
    sideDrawerOpen: state.appReducer.sideDrawerOpen,
})

const mapDispatchToProps = (dispatch) => ({
    drawerToggleClickToolbar: () => dispatch(sideDrawer_open()),
    backdropClickHandler: () => dispatch(sideDrawer_close())
})

export default connect(mapStateToProps, mapDispatchToProps)(DrawerToggleButton);