import { ALERT_BEGIN, ALERT_SHOW, ALERT_HIDE } from '../constants/Alert-action-type';

export const alertBegin = (bg_color) => ({
    type: ALERT_BEGIN,
    payload: {
        bg_color: bg_color
    }
})

export const alertShow = (msg) => ({
    type: ALERT_SHOW,
    payload: {
        msg: msg
    }
})

export const alertHide = () => ({
    type: ALERT_HIDE
})





