import Loadable from "react-loadable";
import CusLoader from "../components/CusLoader";


// Dashboard(總覽模式、排行模式)
export const Dashboard = Loadable({ loader: () => import('../page/Dashboard/index'),loading:CusLoader,});
export const DashboardRank = Loadable({ loader: () => import('../page/Dashboard/DashboardRank'),loading:CusLoader,});
// 單間銀行資訊(分行數據)
export const Bank = Loadable({ loader: () => import('../page/Bank'),loading:CusLoader,});
// 分行數據(電表列表)
export const Meter = Loadable({ loader: () => import('../page/Meter'),loading:CusLoader,});
// 電表清單
export const MeterList = Loadable({ loader: () => import('../page/MeterList'),loading:CusLoader,});
// 用電排行
export const ElectricityRank = Loadable({ loader: () => import('../page/ElectricityRank'),loading:CusLoader,});
// 最適電費
export const BestRatePlan = Loadable({ loader: () => import('../page/BestRatePlan'),loading:CusLoader,});
// 需量最佳化 
export const BestCC = Loadable({ loader: () => import('../page/BestCC'),loading:CusLoader,});
// 管理與設定
export const AccountManage = Loadable({ loader: () => import('../page/AccountManage'),loading:CusLoader,});
export const AreaManage = Loadable({ loader: () => import('../page/AreaManage'),loading:CusLoader,});
export const BankManage = Loadable({ loader: () => import('../page/BankManage'),loading:CusLoader,});
export const EventRecord = Loadable({ loader: () => import('../page/EventRecord'),loading:CusLoader,});
export const PriceOfElectricity = Loadable({ loader: () => import('../page/PriceOfElectricity'),loading:CusLoader,});
export const ECO5Setting = Loadable({ loader: () => import('../page/ECO5Setting'),loading:CusLoader,});
export const SharedElectricityFee = Loadable({ loader: () => import('../page/SharedElectricityFee'),loading:CusLoader,});
export const Kpi = Loadable({ loader: () => import('../page/Kpi'),loading:CusLoader,});
