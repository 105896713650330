import React from 'react';
import { Link } from 'react-router-dom';

import './footer.scss';


const Footer = ()=>{
    return(
        <footer className="footer text-right" style={{fontSize:'12px'}}>Copyright © 2020 艾可智能科技股份有限公司ECOSMART  亞太電信 版權所有 <Link to='/PrivacyPolicy' >隱私權政策及網站安全政策</Link></footer>
    )
}

export default Footer;