import {
    UPDATE_DEVICEID,
    UPDATE_NAVCURIDX,
    UPDATE_METER,
} from '../constants/Meter-action-type';


const initialState = {
    deviceID: '', //裝置id
    meterlist: [], //顯示資訊
    meterlistError: '', //顯示錯誤訊息
    navCurIdx: 0,//由第一筆開始
}

//電表數據
const MeterReducer = (state = initialState, action) => {
    switch (action.type) {
        // 更新裝置id
        case UPDATE_DEVICEID:
            return {
                ...state,
                deviceID: action.payload.deviceID,
            }
        case UPDATE_NAVCURIDX:
            return {
                ...state,
                navCurIdx: action.payload.navCurIdx,
            }
        case UPDATE_METER:
            return {
                ...state,
                meterlist: action.payload.meterlist,
                meterlistError: action.payload.meterlistError,
            }
        default:
            return state
    }
}

export default MeterReducer