export const INIT_BANK = 'INIT_BANK';
export const SET_BANKCODE = 'SET_BANKCODE';

export const FETCH_BANKINFO_BEGIN = 'FETCH_BANKINFO_BEGIN';
export const FETCH_BANKINFO_SUCCESS = 'FETCH_BANKINFO_SUCCESS';
export const FETCH_BANKINFO_ERROR = 'FETCH_BANKINFO_ERROR';

export const FETCH_METERLIST_BEGIN = 'FETCH_METERLIST_BEGIN';
export const FETCH_METERLIST_SUCCESS = 'FETCH_METERLIST_SUCCESS';
export const FETCH_METERLIST_ERROR = 'FETCH_METERLIST_ERROR';

export const FETCH_INFO_BEGIN = 'FETCH_INFO_BEGIN';
export const FETCH_INFO_SUCCESS = 'FETCH_INFO_SUCCESS';
export const FETCH_INFO_ERROR = 'FETCH_INFO_ERROR';
