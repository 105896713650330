import {
    // FETCH_DFLIST_BEGIN,
    FETCH_DFLIST_SUCCESS,
    FETCH_DFLIST_ERROR,
} from '../constants/DFList-action-type';

// 初始化
const initialState = {
    // token: '',
    DFList: [],
    DFListError: ''
}

const DFListReducer = (state = initialState , action) => {
    switch(action.type) {
        // case FETCH_DFLIST_BEGIN:
        //     return {
        //         ...state,
        //         // token: action.payload.token
        //     }
        case FETCH_DFLIST_SUCCESS:
            return {
                ...state,
                DFList: action.payload.DFList,
                DFListError: '',
            }
        case FETCH_DFLIST_ERROR:
            return {
                ...state,
                DFList: [],
                DFListError: action.payload.DFListError,
            }
        default: 
            return state
    }
}
export default DFListReducer;