import { call, put, takeLatest } from "redux-saga/effects";
import {
    GET_TYPE,
    FETCH_CITYRANK_BEGIN,
    FETCH_CITYRANK_SUCCESS,
    FETCH_CITYRANK_ERROR,
} from '../constants/CityRank-action-type';
import { cityRankUrl } from '../utils/apiList';


// 變更用電種類
export const changeType = ( data ) => ({
    type: GET_TYPE,
    payload: {
        type: data.type,
        tabIdx: data.tabIdx,

    }
})
export const fetchCityRankBegin = (data) => ({
    type: FETCH_CITYRANK_BEGIN,
    payload: {
        token: data.token,
        type: data.type,
        cityRankLoading: true,
        total: {},
        cityRank:[],
        cityRankError:'',
    }
})

export const fetchCityRankSuccess = (data) => ({
    type: FETCH_CITYRANK_SUCCESS,
    payload: {
        total: data.Total,
        cityRankLoading: false,
        cityRank: data.CityGroup,
        cityRankError:'',
    }
})

export const fetchCityRankError = (error) => ({
    type: FETCH_CITYRANK_ERROR,
    payload: {
        total:{},
        cityRankLoading: true,
        cityRank:[],
        cityRankError:error,
    }
})

function* fetchCityRank(obj) {
    const {token,type} = obj.payload;
    try{
        const url = cityRankUrl({type});
        const data = yield call(
            () => fetch(url,{
                method: 'GET',
                headers: new Headers({
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'token': token,
                })
            })
            .then( response => response.json())
        )

        if( data.code === '00' && data.msg) {
            yield put(fetchCityRankSuccess(data.msg))
        }else {
            yield put(fetchCityRankError(data.msg))
        }
    }catch(error){
        yield put(fetchCityRankError(error))
    }
}

export function* CityRankSaga() {
    yield takeLatest('FETCH_CITYRANK_BEGIN',fetchCityRank)
}