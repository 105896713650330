import { call, put, takeLatest } from "redux-saga/effects";
import {
    FETCH_KPI_BEGIN,
    FETCH_KPI_SUCCESS,
    FETCH_KPI_ERROR,
} from '../constants/KPI-action-type';
import { KPIListUrl } from '../utils/apiList';


export const fetchKPIBegin = (token) => ({
    type: FETCH_KPI_BEGIN,
    payload: {
        token: token,
        KPIList:{},
        KPIErrror:'',
        KPILoading: true,
    }
})

export const fetchKPISuccess = (data) => ({
    type: FETCH_KPI_SUCCESS,
    payload: {
        KPIList: data,
        KPIErrror:'',
        KPILoading: false,
    }
})

export const fetchKPIError = (error) => ({
    type: FETCH_KPI_ERROR,
    payload: {
        KPIList: {},
        KPIErrror: error,
        KPILoading: true,
    }
})

function* fetchKPI(obj) {
    const { token } =obj.payload;
    try{
        const url = KPIListUrl();
        const data = yield call(
            () => fetch(url,{
                method:"GET",
                headers: new Headers({
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'token': token,
                }),
            })
                .then(response => response.json())
        )
        if(data.code === '00') {
            yield put(fetchKPISuccess(data.msg));
        } else {
            yield put(fetchKPIError(data.msg));
        }
        
    }catch(error){
        console.error('error',error)
        yield put(fetchKPIError(error))
    }
}
export function* KPISaga() {
    yield takeLatest('FETCH_KPI_BEGIN',fetchKPI)
}