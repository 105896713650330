import { call, put , takeLatest  } from 'redux-saga/effects';
import {
    INIT_ACCOUNT_TABLE,
    INIT_ACCOUNT_INFO,
    CHANGE_ACCOUNT_INFO,
    CHANGE_RESULTTABLE, //關鍵字搜尋後的資料
    FETCH_ACCOUNT_BEGIN,
    FETCH_ACCOUNT_SUCCESS,
    FETCH_ACCOUNT_ERROR,
    RESET_ACCOUNT,
}from '../constants/Account-action-type';
import { adminSteupUrl } from '../utils/apiList';



export const initAccountTable = () => ({
    type: INIT_ACCOUNT_TABLE,
    payload: {
        token: '',
        accountTable: {
            AccountCount: 0,
            EnabledCount: 0,
            List: [],
            SuspendCount: 0,
        },
        accountTableResult: [],
        accountTableError: '',
        accountInfo: {
            BankName: '',
            Account: '',
            UserName:'',
            Email: '',
            Enabled: '',
            Suspend: 0,
            BankCode: '',
            RankDesc: '',
            RankCode: 0,
            AreaCodeNo: [],
        },
    }
})
export const initAccountInfo = () => ({
    type: INIT_ACCOUNT_INFO,
    payload: {
        accountInfo: {
            BankName: '',
            Account: '',
            UserName:'',
            Email: '',
            Enabled: '',
            Suspend: 0,
            BankCode: '',
            RankDesc: '',
            RankCode: 0,
            AreaCodeNo: [],
        }
    }
})
export const changeAccountInfo = (data) => ({
    type: CHANGE_ACCOUNT_INFO,
    payload: {
        accountInfo: data
    }
})
export const changeResultTable = (data) => ({
    type: CHANGE_RESULTTABLE,
    payload: {
        accountTableResult: data,
    }
})

export const fetchAccountBegin = (data) => ({
    type: FETCH_ACCOUNT_BEGIN,
    payload: {
        token: data.token,
        Account: data.Account,
        City: data.City,
        PostCodeNo: data.PostCodeNo,
        BankCode: data.BankCode,
        RankCode: data.RankCode,
    }
})
export const fetchAccountSuccess = (data) => ({
    type: FETCH_ACCOUNT_SUCCESS,
    payload: {
        count: {
            AccountCount: data.AccountCount,
            EnabledCount: data.EnabledCount,
            SuspendCount: data.SuspendCount,
        },
        accountTable: [...data.List],
        accountTableResult: [...data.List],
        accountTableError: '',
        accountInfo: {
            BankName: '',
            Account: '',
            UserName:'',
            Email: '',
            Enabled: '',
            Suspend: 0,
            BankCode: '',
            RankDesc: '',
            RankCode: 0,
            AreaCodeNo: [],
        },
    }
})
export const fetchAccountError = (error) => ({
    type: FETCH_ACCOUNT_ERROR,
    payload: {
        count: {
            AccountCount: 0,
            EnabledCount: 0,
            SuspendCount: 0,
        },
        accountTable: [],
        accountTableResult: [],
        accountTableError: error,
        accountInfo: {
            BankName: '',
            Account: '',
            UserName:'',
            Email: '',
            Enabled: '',
            Suspend: 0,
            BankCode: '',
            RankDesc: '',
            RankCode: 0,
            AreaCodeNo: [],
        },
    }
})
export const ResetAccount = (error) => ({
    type: RESET_ACCOUNT,
    payload: {
        count: {
            AccountCount: 0,
            EnabledCount: 0,
            SuspendCount: 0,
        },
        accountTable: [],
        accountTableResult: [],
        accountTableError: '',
        accountInfo: {
            BankName: '',
            Account: '',
            UserName:'',
            Email: '',
            Enabled: '',
            Suspend: 0,
            BankCode: '',
            RankDesc: '',
            RankCode: 0,
            AreaCodeNo: [],
        },
    }
})

function* fetchAccountTable(obj){
    const { token,Account,City,PostCodeNo,BankCode,RankCode } = obj.payload;
    try {
        const url = adminSteupUrl({Account,City,PostCodeNo,BankCode,RankCode,type:''});
        const data = yield call(
            () => fetch(url,{
                method:'GET',
                headers: new Headers({
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'token': token,
                }),
            }).then(response => response.json())
        )

        if( data.code === '00') {
            yield put(fetchAccountSuccess(data.msg))
        }else{
            yield put(fetchAccountError(data.msg))
        }
    }catch(error){
        yield put(fetchAccountError(error))
    }
}

export function* AccountSaga() {
    yield takeLatest('FETCH_ACCOUNT_BEGIN',fetchAccountTable)
}
