import {
    FETCH_KPI_BEGIN,
    FETCH_KPI_SUCCESS,
    FETCH_KPI_ERROR,
} from '../constants/KPI-action-type';

const initialState = {
    // token: '',
    KPIList: {},
    KPIError: '',
    KPILoading: true,
}

const KPIReducer = ( state = initialState , action) => {
    switch(action.type){
        case FETCH_KPI_BEGIN:
            return {
                ...state,
                // token: action.payload.token,
                KPIList: {},
                KPIError: '',
                KPILoading: true,
            }
        case FETCH_KPI_SUCCESS:
            return {
                ...state,
                KPIList: action.payload.KPIList,
                KPIError: '',
                KPILoading: false,
            }
        case FETCH_KPI_ERROR:
            return {
                ...state,
                KPIList: {},
                KPIError: action.payload.KPIError,
                KPILoading: true,
            }
        default:
            return state
    }
}
export default KPIReducer;