import { LOGOUT_BEGIN, CHANGE_TIME, LOGOUT_END, SIDEDRAWER_OPEN, SIDEDRAWER_CLOSE } from '../constants/App-action-type';

const initialState = {
    sideDrawerOpen: false,
    time: 3
}

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGOUT_BEGIN:
            return {
                ...state
            }
        case CHANGE_TIME:
            return {
                ...state,
                time: action.payload.time
            }
        case LOGOUT_END:
            return {
                sideDrawerOpen: false,
                time: 3
            }
        case SIDEDRAWER_OPEN:
            return {
                ...state,
                sideDrawerOpen: true
            }
        case SIDEDRAWER_CLOSE:
            return {
                ...state,
                sideDrawerOpen: false
            }
        default:
            return state
    }
}

export default appReducer;