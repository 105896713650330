import {
    FETCH_WIRINGLIST_BEGIN,
    FETCH_WIRINGLIST_SUCCESS,
    FETCH_WIRINGLIST_ERROR
} from '../constants/WiringList-action-type';
// 初始化
const initialState = {
    // token: '',
    wiringList: [],
    wiringListError: '',
}

const WiringListReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_WIRINGLIST_BEGIN:
            return {
                ...state,
                // token: action.payload.token,
            }
        case FETCH_WIRINGLIST_SUCCESS:
            return {
                ...state,
                wiringList: action.payload.wiringList,
                wiringListError: ''
            }
        case FETCH_WIRINGLIST_ERROR:
            return {
                ...state,
                wiringList: [],
                wiringListError: action.payload.wiringListError
            }
        default:
            return state
    }
}
export default WiringListReducer;