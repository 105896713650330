import {
    // FETCH_RANKLIST_BEGIN,
    FETCH_RANKLIST_SUCCESS,
    FETCH_RANKLIST_ERROR,
} from '../constants/RankList-action-type';

// 初始化
const initialState = {
    // token: '',
    rankList: [],
    rankListError: ''
}

const RankListReducer = (state = initialState , action) => {
    switch(action.type) {
        // case FETCH_RANKLIST_BEGIN:
        //     return {
        //         ...state,
        //         // token: action.payload.token
        //     }
        case FETCH_RANKLIST_SUCCESS:
            return {
                ...state,
                rankList: action.payload.rankList,
                rankListError: '',
            }
        case FETCH_RANKLIST_ERROR:
            return {
                ...state,
                rankList: [],
                rankListError: action.payload.rankListError,
            }
        default: 
            return state
    }
}
export default RankListReducer;