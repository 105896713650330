import {
    INIT_ACCOUNT_TABLE,
    INIT_ACCOUNT_INFO,
    CHANGE_ACCOUNT_INFO,
    CHANGE_RESULTTABLE, //關鍵字搜尋後的資料
    FETCH_ACCOUNT_BEGIN,
    FETCH_ACCOUNT_SUCCESS,
    FETCH_ACCOUNT_ERROR,
}from '../constants/Account-action-type';

const initialState = {
    count: {
        AccountCount: 0,
        EnabledCount: 0,
        SuspendCount: 0,
    },
    accountTable: [],
    accountTableResult: [], //關鍵字搜尋後
    accountTableError: '',
    accountInfo: {
        BankName: '',
        Account: '',
        UserName:'',
        Email: '',
        Enabled: '',
        Suspend: 0,
        BankCode: '',
        RankDesc: '',
        RankCode: 0,
        AreaCodeNo: [],
    },
}
// AccountSaga
const AccountReducer = (state = initialState , action) => {
    switch(action.type) {
        case INIT_ACCOUNT_TABLE:
            return {
                count: {
                    AccountCount: 0,
                    EnabledCount: 0,
                    SuspendCount: 0,
                },
                accountTable: [],
                accountTableResult: [], //關鍵字搜尋後
                accountTableError: '',
                accountInfo: {
                    BankName: '',
                    Account: '',
                    UserName:'',
                    Email: '',
                    Enabled: '',
                    Suspend: 0,
                    BankCode: '',
                    RankDesc: '',
                    RankCode: 0,
                    AreaCodeNo: [],
                },
            }
        case INIT_ACCOUNT_INFO:
            return {
                ...state,
                accountInfo: {
                    BankName: '',
                    Account: '',
                    UserName:'',
                    Email: '',
                    Enabled: '',
                    Suspend: 0,
                    BankCode: '',
                    RankDesc: '',
                    RankCode: 0,
                    AreaCodeNo: [],
                },
            }
        case CHANGE_ACCOUNT_INFO:
            return {
                ...state,
                accountInfo: action.payload.accountInfo,
            }
        case CHANGE_RESULTTABLE:
            return {
                ...state,
                accountTableResult: [],
            }
        case FETCH_ACCOUNT_BEGIN:
            return {
                ...state,
                count: {
                    AccountCount: 0,
                    EnabledCount: 0,
                    SuspendCount: 0,
                },
                accountTable: [],
                accountTableResult: [], //關鍵字搜尋後
                accountTableError: '',
                accountInfo: {
                    BankName: '',
                    Account: '',
                    UserName:'',
                    Email: '',
                    Enabled: '',
                    Suspend: 0,
                    BankCode: '',
                    RankDesc: '',
                    RankCode: 0,
                    AreaCodeNo: [],
                },
            }
        case FETCH_ACCOUNT_SUCCESS:
            return {
                ...state,
                count: action.payload.count,
                accountTable: action.payload.accountTable,
                accountTableResult: action.payload.accountTable, //關鍵字搜尋後
                accountTableError: '',
                accountInfo: {
                    BankName: '',
                    Account: '',
                    UserName:'',
                    Email: '',
                    Enabled: '',
                    Suspend: 0,
                    BankCode: '',
                    RankDesc: '',
                    RankCode: 0,
                    AreaCodeNo: [],
                },
            }
        case FETCH_ACCOUNT_ERROR:
            return {
                ...state,
                count: {
                    AccountCount: 0,
                    EnabledCount: 0,
                    SuspendCount: 0,
                },
                accountTable: [],
                accountTableResult: [], //關鍵字搜尋後
                accountTableError: action.payload.accountTableError,
                accountInfo: {
                    BankName: '',
                    Account: '',
                    UserName:'',
                    Email: '',
                    Enabled: '',
                    Suspend: 0,
                    BankCode: '',
                    RankDesc: '',
                    RankCode: 0,
                    AreaCodeNo: [],
                },
            }
        default:
            return state
    }
}
export default AccountReducer;