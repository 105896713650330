import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
// for ie
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
// style
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { store, persistor } from "./store";
// component
import App from "./App";
import Login from "./login/Login";
import PrivacyPolicy from './components/Footer/PrivacyPolicy';

const theme = createMuiTheme({
	typography: {
		fontFamily: [
      "微軟正黑體", 
      "Microsoft JhengHei",
      "Open Sans",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
		].join(","),
	},
});

ReactDOM.render(
	<HashRouter>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ThemeProvider theme={theme}>
					<Switch>
						<Route path='/login' component={Login} />
						{/* 隱私權政策及網站安全政策 */}
						<Route path='/PrivacyPolicy' component={PrivacyPolicy} />
						<Route path='/' component={App} />
					</Switch>
				</ThemeProvider>
			</PersistGate>
		</Provider>
	</HashRouter>,
	document.getElementById("root")
);

// yarn add react-transition-group --save
// yarn add prop-types --save
// yarn add bootstrap --save
// yarn add react-router-dom --save
// yarn add redux --save
// yarn add react-redux --save
// yarn add redux-saga --save
// yarn add redux-persist --save
// yarn add redux-logger --save
// yarn add @fortawesome/fontawesome-free
// yarn add sass-loader
// yarn add node-sass
// yarn add leaflet
// yarn add react-app-polyfill
// yarn add classnames
// yarn add moment
// yarn add @material-ui/core
// yarn add @material-ui/lab
// yarn add @material-ui/icons
// yarn add @material-ui/pickers --save
// yarn add react-js-pagination
// yarn add @createnl/grouped-checkboxes --save
// yarn add react-spring
// yarn add react-checkbox-tree
// yarn add react-chartjs-2 --save 
// yarn add chart.js
// yarn add chartjs-plugin-zoom
// yarn add chartjs-plugin-annotation --save
// yarn add react-datepicker --save
// yarn add clone
// yarn add react-number-format --save
// yarn add @brainhubeu/react-carousel
// yarn add resize-observer-polyfill
// yarn add react-loadable
// yarn add chartjs-plugin-datalabels


