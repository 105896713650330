import {
    GET_TYPE,
    FETCH_CITYRANK_BEGIN,
    FETCH_CITYRANK_SUCCESS,
    FETCH_CITYRANK_ERROR,
} from '../constants/CityRank-action-type';
// 初始化
const initialState = {
    // token:'',
    type:'W',
    tabIdx: 0,
    total:{},
    cityRankLoading: true,
    cityRank:[],
    cityRankError:''
}
const CityRankReducer = (state = initialState , action) => {
    switch(action.type) {
        case GET_TYPE:
            return {
                ...state,
                type: action.payload.type,
                tabIdx: action.payload.tabIdx,
            }
        case FETCH_CITYRANK_BEGIN: 
            return {
                ...state,
                // token: action.payload.token,
                type: action.payload.type,
                cityRankLoading: true,
                total: {},
                cityRank: [],
                cityRankError:''
            }
        case FETCH_CITYRANK_SUCCESS:
            return {
                ...state,
                cityRankLoading: false,
                total: action.payload.total,
                cityRank: action.payload.cityRank,
                cityRankError:''
            }
        case FETCH_CITYRANK_ERROR:
            return {
                ...state,
                cityRankLoading: true,
                total:{},
                cityRank: [],
                cityRankError: action.payload.cityRankError
            }
        default:
            return state
    }
}
export default CityRankReducer;