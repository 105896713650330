import {call, put, takeLatest } from 'redux-saga/effects';
import {
    FETCH_AREALIST_BEGIN,
    FETCH_AREALIST_SUCCESS,
    FETCH_AREALIST_ERROR,
    RESET_AREALIST,
} from '../constants/AreaList-action-type';
import { areaListUrl }  from '../utils/apiList';

export const fetchAreaListBegin = (token) => ({
    type: FETCH_AREALIST_BEGIN,
    payload: {
        token: token,
    }
})

export const fetchAreaListSuccess = (data) => ({
    type: FETCH_AREALIST_SUCCESS,
    payload: {
        areaList: data,
        areaListError: '',
    }
})

export const fetchAreaListError = (error) => ({
    type: FETCH_AREALIST_ERROR,
    payload: {
        rankList:[],
        rankListError: error,
    }
})

export const resetAreaList = () => ({
    type: RESET_AREALIST,
    payload: {
        rankList:[],
        rankListError: '',
    }
})

function* fetchAreaList(obj) {
    const { token } = obj.payload;
    try{
        const url = areaListUrl();
        const data = yield call(
            () => fetch(url,{
                method: 'GET',
                headers: new Headers({
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'token': token,
                })
            })
            .then( response => response.json())
        )

        if( data.code === '00' && data.msg) {
            yield put(fetchAreaListSuccess(data.msg.List))
        }else {
            yield put(fetchAreaListError(data.msg))
        }
    }catch(error){
        yield put(fetchAreaListError(error))
    }
}

export function* AreaListSaga() {
    yield takeLatest('FETCH_AREALIST_BEGIN',fetchAreaList)
}