import { 
    FETCH_USAGELIST_BEGIN,
    FETCH_USAGELIST_SUCCESS
    ,FETCH_USAGELIST_ERROR
} from '../constants/UsageList-action-type';
// 初始化
const initialState = {
    // token: '',
    usageList: [],
    usageListError: '',
}
const UsageListReducer = ( state = initialState , action) => {
    switch(action.type) {
        case FETCH_USAGELIST_BEGIN:
            return {
                ...state,
                // token: action.payload.token,
            }
        case FETCH_USAGELIST_SUCCESS:
            return {
                ...state,
                usageList: action.payload.usageList,
                usageListError: ''
            }
        case FETCH_USAGELIST_ERROR:
            return {
                ...state,
                usageList: [],
                usageListError: action.payload.usageListError,
            }
        default:
            return state
    }
}
export default UsageListReducer;