import {call,put,takeEvery} from 'redux-saga/effects';
import {
    FETCH_RATEPLAN_BEGIN,
    FETCH_RATEPLAN_SUCCESS,
    FETCH_RATEPLAN_ERROR
} from '../constants/RatePlanList-action-type';
import { ratePlanListUrl } from '../utils/apiList';



export const fetchRatePlanBegin = (token) => ({
    type: FETCH_RATEPLAN_BEGIN,
    payload: {
        token:token,
        ratePlanList:[],
        ratePlanListError:'',
    }
})

export const fetchRatePlanSuccess = (data) => ({
    type: FETCH_RATEPLAN_SUCCESS,
    payload: {
        ratePlanList: data,
        ratePlanListError: ''
    }
})

export const fetchRatePlanError = (error) => ({
    type: FETCH_RATEPLAN_ERROR,
    payload:{
        ratePlanList: [],
        ratePlanListError: error
    }
})

// 計費方式
function* fetchRatePlan(obj){
    const token = obj.payload.token;

    try{
        const url=ratePlanListUrl();
        const data = yield call(
            () => fetch(url,{
                method: 'GET',
                headers: new Headers({
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'token': token,
                })
            })
            .then( response => response.json())
        )

        if( data.code === '00' && data.msg) {
            yield put(fetchRatePlanSuccess(data.msg.List))
        }else {
            yield put(fetchRatePlanError(data.msg))
        }
    }catch(error){
        yield put(fetchRatePlanError(error))
    }
}

export function* RatePlanListSaga() {
    yield takeEvery('FETCH_RATEPLAN_BEGIN',fetchRatePlan)
}