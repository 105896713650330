import { all } from 'redux-saga/effects';
import { loginSaga } from '../actions/LoginAction';
import { CityRankSaga } from '../actions/CityRankAction';
import { BankRankSaga } from '../actions/BankRankAction';
import { BankSaga } from '../actions/BankAction';
import { AccountSaga } from '../actions/AccountAction';
import { CityListSaga } from '../actions/CityListAction';
import { DistListSaga } from '../actions/DistListAction';
import { BankListSaga } from '../actions/BankListAction';
import { RatePlanListSaga } from '../actions/RatePlanListAction';
import { PATypeListSaga } from '../actions/PATypeListAction';
import { UsageListSaga } from '../actions/UsageListAction';
import { WiringListSaga } from '../actions/WiringListAction';
import { DFListSaga } from '../actions/DFListAction';
import { MeterTypeListSaga } from '../actions/MeterTypeListAction';
import { RankListSaga } from '../actions/RankListAction';
import { AreaListSaga } from '../actions/AreaListAction';
import { KPISaga } from '../actions/KPIAction';

function* rootSaga() {
  yield all([
    loginSaga(),
    // 排行
    CityRankSaga(),
    BankRankSaga(),
    // 各分行數據
    BankSaga(),
    // 帳號管理
    AccountSaga(),
    // 縣市下拉清單(cityGroup,city)
    CityListSaga(),
    // 行政區下拉清單(distlist)
    DistListSaga(),
    // 分行下拉清單
    BankListSaga(),
    // 計費方式清單(下拉)
    RatePlanListSaga(),
    // 電號類別清單(下拉)
    PATypeListSaga(),
    // 耗能分類清單(下拉)
    UsageListSaga(),
    // 接線方式清單(下拉)
    WiringListSaga(),
    // 需量預測模式對照表(下拉)
    DFListSaga(),
    // 電表型號清單(下拉)
    MeterTypeListSaga(),
    // 帳號管理權限清單(下拉)
    RankListSaga(),
    // 帳號管理 區域名稱清單(下拉)
    AreaListSaga(),
    // KPI
    KPISaga(),
  ]);
}

export default rootSaga;