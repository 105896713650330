
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    INIT_BANK,
    SET_BANKCODE,
    FETCH_BANKINFO_BEGIN,
    FETCH_BANKINFO_SUCCESS,
    FETCH_BANKINFO_ERROR,
} from '../constants/Bank-action-type';
import { bankUrl } from '../utils/apiList';


export const initBank = () => ({
    type: INIT_BANK,
    payload: {
        token: '',
        bankCode: '',
        bankInfo: [],
        bankInfoError: '',
        meterList: [],
        meterListError: '',
    }
})
export const setBankCode = (bankCode) => ({
    type: SET_BANKCODE,
    payload: {
        bankCode: bankCode,
    }
})

// 分行資訊
export const fetchBankInfoBegin = (data) => ({
    type: FETCH_BANKINFO_BEGIN,
    payload: {
        token: data.token,
        bankCode: data.bankCode,
        account: data.account,
        bankInfo: [],
        bankInfoError: '',
    }
})
export const fetchBankInfoSuccess = (data) => ({
    type: FETCH_BANKINFO_SUCCESS,
    payload: {
        bankInfo: data,
        bankInfoError: '',
    }
})
export const fetchBankInfoError = (error) => ({
    type: FETCH_BANKINFO_ERROR,
    payload: {
        bankInfo: [],
        bankInfoError: error,
    }
})



function* fetchBankInfo(obj) {
    const { token, bankCode, account, } = obj.payload;
    try {
        const url = bankUrl({bankCode,account});
        const data = yield call(
            () => fetch(url, {
                method: "GET",
                headers: new Headers({
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'token': token,
                }),
            })
                .then(response => response.json())
        )
        if (data.code === '00') {
            yield put(fetchBankInfoSuccess(data.msg.Bank));
        } else {
            yield put(fetchBankInfoError(data.msg));
        }
    } catch (error) {
        yield put(fetchBankInfoError(error))
    }
}



export function* BankSaga() {
    yield takeLatest('FETCH_BANKINFO_BEGIN', fetchBankInfo)
    // yield takeLatest('FETCH_METERLIST_BEGIN',fetchMeterList)
}