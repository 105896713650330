import {
    UPDATE_METERLIST_LIST_SUCCESS,
    UPDATE_METERLIST_STATUS_SUCCESS,
    UPDATE_METERLIST_CITY_SUCCESS,
    UPDATE_METERLIST_DIST_SUCCESS,
    UPDATE_METERLIST_BANK_SUCCESS,
    UPDATE_METERLIST_USAGEDESC_SUCCESS,
    UPDATE_METERLIST_TABLEDATA_SUCCESS
} from '../constants/MeterList-action-type';
// 初始化
const initialState = {
    //{ idx: 陣列第幾個 ,name: '屬性對照', text: '中文對照', active: 顏色對照, sort: '按鈕對照',checked: CHK對照,,itemdata:[已選取項目], itemList: [全部選項]  }
    //Forever:永久顯示
    //Select_M:多選
    //Button:按鈕 
    list: {
        'StatusCode': { idx: 0, name: 'StatusCode', text: '狀態', active: true, sort: 'Select_M', checked: false, itemdata: [], itemList: [] },
        'City': { idx: 1, name: 'City', text: '縣市', active: false, sort: 'Select_M', checked: false, itemdata: [], itemList: [] },
        'Dist': { idx: 2, name: 'Dist', text: '行政區', active: false, sort: 'Select_M', checked: false, itemdata: [], itemList: [] },
        'BankCode': { idx: 3, name: 'BankCode', text: '代號/分行名稱 ', active: true, sort: 'Select_M', checked: false, itemdata: [], itemList: [] },
        'PowerAccount': { idx: 4, name: 'PowerAccount', text: '電號', active: false, sort: 'Button' },
        'MeterName': { idx: 5, name: 'MeterName', text: '電表名稱', active: true, sort: 'Button' },
        'UsageDesc': { idx: 6, name: 'UsageDesc', text: '耗能分類', active: false, sort: 'Select_M', checked: false, itemdata: [], itemList: [] },
        'DeviceID': { idx: 7, name: 'DeviceID', text: 'DeviceID', active: false, sort: 'Forever' },
        'Enabled': { idx: 8, name: 'Enabled', text: '啟用狀態', active: false, sort: 'Button' },
        'VI': { idx: 9, name: 'VI', text: '電壓/電流', active: false, sort: 'Button' },
        'W': { idx: 10, name: 'W', text: '功率', active: true, sort: 'Button' },
        'RPVA': { idx: 11, name: 'RPVA', text: '虛功/視在', active: false, sort: 'Button' },
        'PF': { idx: 12, name: 'PF', text: '功因', active: false, sort: 'Button' },
        'Hz': { idx: 13, name: 'Hz', text: '頻率', active: false, sort: 'Button' },
        'DF': { idx: 14, name: 'DF', text: '需量預測', active: false, sort: 'Button' },
        'RecTime': { idx: 15, name: 'RecTime', text: '最新資料時間', active: false, sort: 'Forever' },
        'ConnectionTime': { idx: 16, name: 'ConnectionTime', text: '最新連線時間', active: false, sort: 'Forever' },
    },
    statusCodeData: [],//ajax statusCode data
    cityData: [],//ajax city data
    distData: [],//ajax dist data
    bankCodeData: [],//ajax bankCode data
    usageDescData: [],//ajax usageDesc data    
    tableData: [],//ajax tableData data
}
const MeterListReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_METERLIST_LIST_SUCCESS:
            return {
                ...state,
                list: action.payload.list,
            }
        case UPDATE_METERLIST_STATUS_SUCCESS:
            return {
                ...state,
                statusCodeData: action.payload.statusCodeData,
            }
        case UPDATE_METERLIST_CITY_SUCCESS:
            return {
                ...state,
                cityData: action.payload.cityData,
            }
        case UPDATE_METERLIST_DIST_SUCCESS:
            return {
                ...state,
                distData: action.payload.distData,
            }
        case UPDATE_METERLIST_BANK_SUCCESS:
            return {
                ...state,
                bankCodeData: action.payload.bankCodeData,
            }
        case UPDATE_METERLIST_USAGEDESC_SUCCESS:
            return {
                ...state,
                usageDescData: action.payload.usageDescData,
            }
        case UPDATE_METERLIST_TABLEDATA_SUCCESS:
            return {
                ...state,
                tableData: action.payload.tableData,
            }
        default:
            return state
    }
}
export default MeterListReducer;
