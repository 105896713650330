import { call, put, takeLatest } from "redux-saga/effects";
import {
    FETCH_BANKRANK_BEGIN,
    FETCH_BANKRANK_SUCCESS,
    FETCH_BANKRANK_ERROR,
    RESET_BANKRANK,
} from '../constants/BankRank-action-type';
import { BankRankUrl } from '../utils/apiList';
import { getBankRankList,getChartTitle,getChartKPI,getChartKPIUnit,getMaxxAxes } from '../page/Dashboard/BankRankChart/utils';


export const fetchBankRankBegin = (data) => ({
    type: FETCH_BANKRANK_BEGIN,
    payload: {
        token: data.token,
        type: data.type,
        KPIList: data.KPIList,
        bankRank:[],
        bankRankChart: {
            Data: {labelList:[], valueList:[], colorList:[], bankCodeList:[], link:''},
            Title: '',
            Unit: '',
            MaxxAxes: 0,
            KPI: null,
        },
        bankRankEPUIChart: {
            Data: {labelList:[], valueList:[], colorList:[], bankCodeList:[], link:''},
            KPI: null,
        },
        bankRankError:'',
    }
})

export const fetchBankRankSuccess = (obj) => ({
    type: FETCH_BANKRANK_SUCCESS,
    payload: {
        bankRank: obj.data,
        bankRankChart: obj.bankRankChart,
        bankRankEPUIChart: obj.bankRankEPUIChart,
        bankRankError:'',
    }
})

export const fetchBankRankError = (error) => ({
    type: FETCH_BANKRANK_ERROR,
    payload: {
        bankRank:[],
        bankRankChart: {
            Data: {labelList:[], valueList:[], colorList:[], bankCodeList:[], link:''},
            Title: '',
            Unit: '',
            MaxxAxes: 0,
            KPI: null,
        },
        bankRankEPUIChart: {
            Data: {labelList:[], valueList:[], colorList:[], bankCodeList:[], link:''},
            KPI: null,
        },
        bankRankError:error,
    }
})

export const resetBankRank = () => ({
    type: RESET_BANKRANK,
    payload: {
        bankRank:[],
        bankRankChart: {
            Data: {labelList:[], valueList:[], colorList:[], bankCodeList:[], link:''},
            Title: '',
            Unit: '',
            MaxxAxes: 0,
            KPI: null,
        },
        bankRankEPUIChart: {
            Data: {labelList:[], valueList:[], colorList:[], bankCodeList:[], link:''},
            KPI: null,
        },
        bankRankError:'',
    }
})

function* fetchBankRank(obj) {
    const {token, type, KPIList } = obj.payload;
    try{
        const url = BankRankUrl({type});
        const EPUIurl = BankRankUrl({type:'EPUI'});
        const data = yield call(
            () => fetch(url,{
                method: 'GET',
                headers: new Headers({
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'token': token,
                })
            })
            .then( response => response.json())
        )

        if( data.code === '00' && data.msg) {
            const bankRankChart = {
                Data: getBankRankList(data.msg.Bank, type, KPIList),
                Title: getChartTitle(type),
                Unit: getChartKPIUnit(type,KPIList),
                MaxxAxes: getMaxxAxes(type),
                KPI: getChartKPI(type,KPIList),
            }
            const bankRankEPUIChart = {
                Data: {labelList:[], valueList:[], colorList:[], bankCodeList:[], link:''},
                KPI: getChartKPI('EPUI',KPIList),
            };
            if(type === "EUI"){
                try {
                    const EPUIData = yield call(
                        () => fetch(EPUIurl,{
                            method: 'GET',
                            headers: new Headers({
                                'Accept': '*/*',
                                'Content-Type': 'application/json',
                                'token': token,
                            })
                        })
                        .then( response => response.json())
                    )
                    if(EPUIData.code === '00' && EPUIData.msg) {
                        bankRankEPUIChart["Data"] = getBankRankList(EPUIData.msg.Bank, 'EPUI', KPIList);
                    }
                }catch(error){
                    yield put(fetchBankRankError(error))
                }
            }

            yield put(fetchBankRankSuccess({data:data.msg.Bank,bankRankChart,bankRankEPUIChart}))
        }else {
            yield put(fetchBankRankError(data.msg))
        }
    }catch(error){
        yield put(fetchBankRankError(error))
    }
}

export function* BankRankSaga() {
    yield takeLatest('FETCH_BANKRANK_BEGIN',fetchBankRank)
}