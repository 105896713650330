import {
    FETCH_DISTLIST_BEGIN,
    FETCH_DISTLIST_SUCCESS,
    FETCH_DISTLIST_ERROR,
} from '../constants/DistList-action-type';


const initialState = {
    // token: '',
    city: '',
    distList: [],
    distListError:'',
}

const DistListReducer = (state = initialState, action) => {
    switch(action.type){
        // 行政區
        case FETCH_DISTLIST_BEGIN:
            return{
                ...state,
                // token: action.payload.token,
                city: action.payload.city,
                distList: [],
                distListError:'',
            }
        case FETCH_DISTLIST_SUCCESS:
            return {
                ...state,
                distList: action.payload.distList,
                distListError:'',
            }
        case FETCH_DISTLIST_ERROR:
            return{
                ...state,
                distList: [],
                distListError: action.payload.distListError,
            }
        default:
            return state
    }
}
export default DistListReducer
