import {call, put, takeLatest } from 'redux-saga/effects';
import {
    FETCH_RANKLIST_BEGIN,
    FETCH_RANKLIST_SUCCESS,
    FETCH_RANKLIST_ERROR,
} from '../constants/RankList-action-type';
import { rankListUrl } from '../utils/apiList';


export const fetchRankListBegin = (data) => ({
    type: FETCH_RANKLIST_BEGIN,
    payload: {
        token: data.token,
        account: data.account,
    }
})

export const fetchRankListSuccess = (data) => ({
    type: FETCH_RANKLIST_SUCCESS,
    payload: {
        rankList: data,
        rankListError: '',
    }
})

export const fetchRankListError = (error) => ({
    type: FETCH_RANKLIST_ERROR,
    payload: {
        rankList:[],
        rankListError: error,
    }
})

function* fetchRankList(obj) {
    const { token,account } = obj.payload;
    try{
        // const url = `${apiUrl}getRankListEdit?account=${account}`;
        const url = rankListUrl({account});
        const data = yield call(
            () => fetch(url,{
                method: 'GET',
                headers: new Headers({
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'token': token,
                })
            })
            .then( response => response.json())
        )

        if( data.code === '00' && data.msg) {
            yield put(fetchRankListSuccess(data.msg.List))
        }else {
            yield put(fetchRankListError(data.msg))
        }
    }catch(error){
        yield put(fetchRankListError(error))
    }
}

export function* RankListSaga() {
    yield takeLatest('FETCH_RANKLIST_BEGIN',fetchRankList)
}