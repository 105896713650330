import React , { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { updateAccount, updatePassWord, fetchDataBegin } from '../../actions/LoginAction';
import PropTypes from 'prop-types';

class LoginContent extends Component {
    constructor(props){
        super(props)
        this.state={}
    }

    render(){
        const { account, password } = this.props;
        return (
            <Fragment>
                {this.props.error && <div className="alert alert-warning">{this.props.error}</div>}

                <div className='login__field'>
                    <input type="text" value={account} onChange={(event) => this.changeAccount(event)} />
                    <label>帳號</label>
                </div>
                <div className='login__field'>
                    <input type='password' value={password} onChange={(event) => this.changePassWord(event)} />
                    <label>密碼</label>
                </div>

                <button type='submit' className='w-100 btn btn-success' onClick={() => this.loginSubmitHandler()} >登入 <i className="fas fa-sign-in-alt ml-1 mb-1" /></button>
            </Fragment>
        )
    }

    changeAccount(event) {
        let inputVal = event.target.value;
        this.props.updateAccount(inputVal);
    }

    changePassWord(event) {
        let inputVal = event.target.value;
        this.props.updatePassWord(inputVal);
    }

    loginSubmitHandler() {
        const userObj = {
            account: this.props.account,
            password: this.props.password
        }
        this.props.submit(userObj);
    }
}



LoginContent.propTypes = {
    account: PropTypes.string,
    password: PropTypes.string,
    updateAccount: PropTypes.func,
    updatePassWord: PropTypes.func,
    submit: PropTypes.func,

}

const mapStateToProps = (state, ownProps) => {
    return {
        account: state.loginReducer.account,
        password: state.loginReducer.password,
        token: state.loginReducer.token,
        error: state.loginReducer.error,
        redirectToReferrer: state.loginReducer.redirectToReferrer,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updateAccount: (value) => dispatch(updateAccount(value)),
        updatePassWord: (value) => dispatch(updatePassWord(value)),
        submit: (userObj) => dispatch(fetchDataBegin(userObj))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginContent)