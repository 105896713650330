import {
    FETCH_BANKRANK_BEGIN,
    FETCH_BANKRANK_SUCCESS,
    FETCH_BANKRANK_ERROR,
} from '../constants/BankRank-action-type';

// 初始化
const initialState = {
    bankRank:[],
    bankRankChart: {
        Data: {labelList:[], valueList:[], colorList:[], bankCodeList:[], link:''},
        Title: '',
        Unit: '',
        MaxxAxes: 0,
        KPI: null,
    },
    bankRankEPUIChart: {
        Data: {labelList:[], valueList:[], colorList:[], bankCodeList:[], link:''},
        KPI: null,
    },
    bankRankError:'',
}
const BankRankReducer = (state = initialState , action) => {
    switch(action.type) {
        case FETCH_BANKRANK_BEGIN: 
            return {
                ...state,
                bankRank: [],
                bankRankChart: {
                    Data: {labelList:[], valueList:[], colorList:[], bankCodeList:[], link:''},
                    Title: '',
                    Unit: '',
                    MaxxAxes: 0,
                    KPI: null,
                },
                bankRankEPUIChart: {
                    Data: {labelList:[], valueList:[], colorList:[], bankCodeList:[], link:''},
                    KPI: null,
                },
                bankRankError:''
            }
        case FETCH_BANKRANK_SUCCESS:
            return {
                ...state,
                bankRank: action.payload.bankRank,
                bankRankChart: action.payload.bankRankChart,
                bankRankEPUIChart: action.payload.bankRankEPUIChart,
                bankRankError:''
            }
        case FETCH_BANKRANK_ERROR:
            return {
                ...state,
                bankRank: [],
                bankRankChart: {
                    Data: {labelList:[], valueList:[], colorList:[], bankCodeList:[], link:''},
                    Title: '',
                    Unit: '',
                    MaxxAxes: 0,
                    KPI: null,
                },
                bankRankEPUIChart: {
                    Data: {labelList:[], valueList:[], colorList:[], bankCodeList:[], link:''},
                    KPI: null,
                },
                bankRankError: action.payload.bankRankError
            }
        default:
            return state
    }
}
export default BankRankReducer;