import {
    INIT_BANK,
    SET_BANKCODE,
    FETCH_BANKINFO_BEGIN,
    FETCH_BANKINFO_SUCCESS,
    FETCH_BANKINFO_ERROR,

    FETCH_METERLIST_BEGIN,
    FETCH_METERLIST_SUCCESS,
    FETCH_METERLIST_ERROR,
} from '../constants/Bank-action-type'

const initialState = {
    // token: '',
    bankCode: '',
    eco5Account: '',
    bankInfo: {},
    bankInfoError: '',
    meterList: [],
    meterListError: '',
    powerAccount: [],
}

const BankReducer = (state = initialState, action) => {
    switch (action.type) {
        case INIT_BANK:
            return {
                // token: '',
                bankCode: '',
                eco5Account: '',
                bankInfo: {},
                bankInfoError: '',
                meterList: [],
                meterListError: '',
                powerAccount: [],
            }
        case SET_BANKCODE:
            return {
                ...state,
                bankCode: action.payload.bankCode,
            }       
        // 分行資訊
        case FETCH_BANKINFO_BEGIN:
            return {
                ...state,
                // token: action.payload.token,
                bankCode: action.payload.bankCode,
                bankInfo: {},
                bankInfoError: '',
                powerAccount: []
            }
        case FETCH_BANKINFO_SUCCESS:
            return {
                ...state,
                bankInfo: { ...action.payload.bankInfo },
                bankInfoError: '',
                powerAccount: [...action.payload.bankInfo.PowerAccount]
            }
        case FETCH_BANKINFO_ERROR:
            return {
                ...state,
                bankInfo: {},
                bankInfoError: action.payload.bankInfoError,
                powerAccount: []
            }
        // 電表列表
        case FETCH_METERLIST_BEGIN:
            return {
                ...state,
                // token: action.payload.token,
                bankCode: action.payload.bankCode,
                eco5Account: action.payload.eco5Account,
                meterList: [],
                meterListError: '',
            }
        case FETCH_METERLIST_SUCCESS:
            return {
                ...state,
                meterList: [...action.payload.meterList],
                meterListError: '',
            }
        case FETCH_METERLIST_ERROR:
            return {
                ...state,
                meterList: [],
                meterListError: action.payload.meterListError,
            }
        default:
            return state
    }

}
export default BankReducer;