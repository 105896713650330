import {
    DASHBOARD_SELECTEDCITY
} from '../constants/Dashboard-action-type';
// 初始化
const initialState = {
    selectedCity: '',   //展開所選城市
}

const DashboardReducer = (state = initialState, action) => {
    switch(action.type){
        case DASHBOARD_SELECTEDCITY:
            return {
                ...state,
                selectedCity: action.payload.selectedCity,   //展開所選城市
            }
        default:
            return state
    }
}
export default DashboardReducer;