import { ALERT_BEGIN, ALERT_SHOW, ALERT_HIDE } from '../constants/Alert-action-type';

const initialState = {
    msg: '',
    bg_color: 'red',
    isAlert: false,
}

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case ALERT_BEGIN:
            return {
                ...state,
                bg_color: action.payload.bg_color,
                isAlert: true,
            }
        case ALERT_SHOW:
            return {
                ...state,
                msg: action.payload.msg
            }
        case ALERT_HIDE:
            return {
                ...state,
                msg: '',
                isAlert: false,
            }
        default:
            return state
    }
}

export default appReducer;